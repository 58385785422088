<template>
    <div class="number_range_slider">
        <div class="number_range_slider__value number_range_slider__value--first">
            {{ displayValueRange.value1 / 2 }}
        </div>
        <div class="number_range_slider__input_holder">
            <div class="number_range_slider__rail" ref="NumberRangeSliderElement">
                <NumberRangeSliderKnob
                    v-model="percentage1"
                    @dragEnd="snap"
                    :style="{
                        left: `${percentage1 * 100}%`,
                    }"
                />
                <NumberRangeSliderKnob
                    v-model="percentage2"
                    @dragEnd="snap"
                    :style="{
                        left: `${percentage2 * 100}%`,
                    }"
                />
            </div>
            <div class="number_range_slider__visual_rail">
                <div class="number_range_slider__visual_rail_limiter">
                    <div
                        class="number_range_slider__visual_rail_active"
                        :style="visualRangeStyle"
                    ></div>
                </div>
            </div>
        </div>
        <div class="number_range_slider__value number_range_slider__value--last">
            {{ displayValueRange.value2 / 2 }}
        </div>
    </div>
</template>

<script>
import InputBase from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/mixins/InputBase";
import NumberRangeSliderKnob from "./NumberRangeSliderKnob.vue";

export default {
    name: "NumberRangeSlider",
    mixins: [InputBase],
    components: {
        NumberRangeSliderKnob,
    },
    data() {
        return {
            limitRange: {
                min: 1,
                max: 10,
            },
            percentage1: 0,
            percentage2: 1,
        };
    },
    computed: {
        amountNumbersInRange() {
            return this.limitRange.max - this.limitRange.min;
        },
        percentageRange() {
            return [this.percentage1, this.percentage2];
        },
        valueRange() {
            const { amountNumbersInRange, percentageRange, limitRange } = this;
            return {
                value1: limitRange.min + amountNumbersInRange * percentageRange[0],
                value2: limitRange.min + amountNumbersInRange * percentageRange[1],
            };
        },
        snapToRange() {
            const { amountNumbersInRange, valueRange, limitRange } = this;
            const snapToRange = {
                snap1: (Math.round(valueRange.value1) - limitRange.min) / amountNumbersInRange,
                snap2: (Math.round(valueRange.value2) - limitRange.min) / amountNumbersInRange,
            };
            return snapToRange;
        },
        displayValueRange() {
            return {
                value1: Math.round(this.valueRange.value1),
                value2: Math.round(this.valueRange.value2),
            };
        },
        sortedRange() {
            const { displayValueRange } = this;
            return [displayValueRange.value1, displayValueRange.value2].sort((a, b) => a - b);
        },
        visualRangeStyle() {
            const width = (this.percentage2 - this.percentage1) * 100;
            return {
                marginLeft: `${this.percentage1 * 100}%`,
                width: `${width}%`,
            };
        },
    },
    methods: {
        snap() {
            window.requestAnimationFrame(() => {
                this.inputValue = {
                    from: parseInt(this.sortedRange[0], 10),
                    to: parseInt(this.sortedRange[1], 10),
                };
            });
        },
        adaptInputValue() {
            const { inputValue, amountNumbersInRange, limitRange } = this;
            const range = {
                from: (Math.round(inputValue.from) - limitRange.min) / amountNumbersInRange,
                to: (Math.round(inputValue.to) - limitRange.min) / amountNumbersInRange,
            };
            this.percentage1 = range.from;
            this.percentage2 = range.to;
        },
    },
    watch: {
        inputValue() {
            this.adaptInputValue();
        },
    },
    mounted() {
        this.adaptInputValue();
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
.number_range_slider {
    width: 100%;
    height: 56px;
    display: flex;
    padding: 0 16px;
    border-radius: 4px;
    border: 1px solid $grey_mischka;
    margin-bottom: 8px;
}
.number_range_slider__input_holder {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
}
.number_range_slider__rail {
    width: calc(100% - 40px);
    height: 100%;
    position: absolute;
}
.number_range_slider__visual_rail {
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: $grey_mischka;
    overflow: hidden;
}
.number_range_slider__visual_rail_limiter {
    width: calc(100% - 40px);
    height: 100%;
    margin-left: 28px;
}
.number_range_slider__visual_rail_active {
    height: 100%;
    background: $blue;
}
.number_range_slider__value {
    @extend %body2_style;
    width: 24px;
    height: 56px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}
.number_range_slider__value--first {
    justify-content: flex-start;
}
.number_range_slider__value--last {
    justify-content: flex-end;
}
</style>
