<template>
    <div class="inbox_detail_error">
        <main v-if="!reviewOwnerAccess">
            <h5 class="headline5">Error</h5>
            <p class="body2" v-for="error in errors.messages" :key="error">{{ error }}</p>
        </main>
        <section v-if="reviewOwnerLoading" class="inbox_swapable_owner_check">
            <LoaderCircular :height="24" />
            <p>{{ $t("cp__inbox__error__review_access__checking_label") }}</p>
        </section>
        <section v-if="reviewOwnerAccess" class="inbox_swapable_owner">
            <p>{{ $t("cp__inbox__error__review_access__access_allowed") }}</p>
            <LoaderCircular v-if="swapping" :height="24" />
            <Button v-else @click.native="swapToReviewOwner">
                {{ buttonLabel }}
            </Button>
        </section>
    </div>
</template>

<script>
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import { mapActions } from "vuex";
import * as Sentry from "@sentry/vue";
import httpGet from "@/helpers/httpGet";

export default {
    name: "InboxDetailError",
    props: {
        errors: {
            type: Object,
            required: true,
        },
    },
    components: {
        LoaderCircular,
        Button,
    },
    data() {
        return {
            reviewOwnerLoading: false,
            reviewOwner: {},
            reviewOwnerAccess: false,
            swapping: false,
            componentLabels: ["cp__inbox__error__review_access__swap_button__label"],
        };
    },
    computed: {
        reviewID() {
            return this.$route.params.ID;
        },
        buttonLabel() {
            return this.$t("cp__inbox__error__review_access__swap_button__label", {
                shopName: this.reviewOwner.name,
            });
        },
    },
    methods: {
        ...mapActions({
            switchAccount: "AccountStore/switchAccount",
        }),
        swapToReviewOwner() {
            this.swapping = true;
            this.switchAccount({
                uuid: this.reviewOwner.uuid,
                executeOnAccountSwitch: true,
                apolloClient: this.$apollo,
            });
        },
    },
    mounted() {
        const { errors } = this;
        if (errors.status !== 401) return;
        this.reviewOwnerLoading = true;
        httpGet({
            url: `${process.env.VUE_APP_API_BASE_URL}/api/v3/Review/isBoundToMultiLoginShop`,
            urlParams: {
                reviewID: this.reviewID,
            },
        })
            .then(({ data }) => {
                this.reviewOwnerAccess = data.found;
                this.reviewOwner = data.shop;
            })
            .catch((errorMessage) => {
                Sentry.captureException(errorMessage);
            })
            .finally(() => {
                this.reviewOwnerLoading = false;
            });
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox_detail_error {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.headline5 {
    color: $red;
}
.body2 {
    display: block;
}
.inbox_swapable_owner_check {
    @extend %body2_style;
    display: flex;
    align-items: center;
    p {
        margin-left: 8px;
    }
}
.inbox_swapable_owner {
    @extend %body2_style;
    p {
        margin-bottom: 16px;
    }
}
</style>
