<template>
    <div class="inbox_items_header">
        <SearchBar class="search-bar" :value="searchText" @input="onSearchInput($event)" />

        <button
            :disabled="selectState"
            class="inbox_items_header__button"
            id="inbox_filter_button"
            @click="toggleFilter"
        >
            <div v-if="amountOfFiltersActive > 0" class="filters_active_notifier" />
            <font-awesome-icon :icon="['fal', 'filter']" />
        </button>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFilter, faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SearchBar from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/SearchBar.vue";
import animateXtoY from "@feedbackcompany/feedback-company-vue-components/src/helpers/animationHelper";

library.add(faFilter, faSearch);

export default {
    name: "InboxItemsHeader",
    components: {
        FontAwesomeIcon,
        SearchBar,
    },
    computed: {
        ...mapState({
            trayOpenPercentage: (state) => state.InboxStore.filterTrayOpenPercentage,
            searchText: (state) => state.InboxStore.sorting.searchText,
            selectState: (state) => state.InboxStore.selectState,
        }),
        ...mapGetters({
            amountOfFiltersActive: "InboxStore/getAmountOfFiltersActive",
        }),
    },
    methods: {
        ...mapMutations({
            setfilterTrayOpenPercentage: "InboxStore/setFilterTrayOpenPercentage",
        }),
        ...mapActions({
            setSortingParam: "InboxStore/setSortingParam",
        }),
        onSearchInput(data) {
            this.setSortingParam({
                key: "searchText",
                value: data,
            });
        },
        toggleFilter() {
            if (this.trayOpenPercentage === 1) {
                this.closeFilter();
            } else {
                this.openFilter();
            }
        },
        closeFilter() {
            if (window.innerWidth < 1001) {
                animateXtoY({
                    x: this.trayOpenPercentage,
                    y: 0,
                    time: 200,
                    easeName: "easeOutQuad",
                    executable: ({ setTo }) => {
                        this.setfilterTrayOpenPercentage({
                            openPercentage: setTo,
                        });
                    },
                });
            } else {
                this.setfilterTrayOpenPercentage({
                    openPercentage: 0,
                });
            }
        },
        openFilter() {
            if (this.notificationsLoading) return;
            if (window.innerWidth < 1001) {
                animateXtoY({
                    x: this.trayOpenPercentage,
                    y: 1,
                    time: 200,
                    easeName: "easeOutQuad",
                    executable: ({ setTo }) => {
                        this.setfilterTrayOpenPercentage({
                            openPercentage: setTo,
                        });
                    },
                });
            } else {
                this.setfilterTrayOpenPercentage({
                    openPercentage: 1,
                });
            }
        },
    },
    beforeDestroy() {
        if (this.trayOpenPercentage === 1) {
            this.closeFilter();
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox_items_header {
    width: 100%;
    display: flex;
    padding: 8px;
    background: $white;
}

.inbox_items_header__button {
    background: $grey_alabaster;
    border: 1px solid $grey_alabaster;
    border-radius: 6px;
    height: 44px;
    font-size: 16px;

    &:disabled {
        &:hover {
            cursor: default;
        }
    }
}

.search-bar {
    width: 100%;
    display: flex;
    align-items: center;
}

.inbox_items_header__button {
    position: relative;
    flex-shrink: 0;
    width: 44px;
    margin-left: 8px;
    outline: 0;
    &:hover {
        cursor: pointer;
    }
}
.filters_active_notifier {
    display: inline-block;
    position: absolute;
    top: 4px;
    right: 4px;
    width: 8px;
    height: 8px;
    background-color: $blue;
    border-radius: 4px;
}
</style>
