<template>
    <div class="inbox_reply">
        <h1 class="inbox_reply__label">{{ $t("cp__inbox__reply__label") }}</h1>
        <div class="inbox_reply__content">
            <AccountImage
                class="inbox_reply__account_image"
                :size="40"
                :src="activeAccount.logo"
                :initial="accountName[0]"
            />
            <div class="inbox_reply__reply">
                <div class="inbox_reply__bubble">{{ reply }}</div>
                <div class="question_holder__actions">
                    <span class="question_holder__action_btn" @click="requestReplyInputFocus">
                        {{ $t("cp__generic__edit") }}
                    </span>
                    <span class="question_holder__action_btn" @click="requestDelete">
                        {{ $t("cp__generic__delete") }}
                    </span>
                </div>
                <span class="inbox_reply__edit_button" v-if="showEditButton" @click="requestEdit">
                    <FontAwesomeIcon :icon="['far', 'pencil']" />
                    edit
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AccountImage from "@/components/Global/Atoms/AccountImage.vue";

library.add(faPencil);

export default {
    name: "InboxReply",
    components: {
        AccountImage,
        FontAwesomeIcon,
    },
    props: {
        reply: {
            type: String,
            required: true,
        },
        showEditButton: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
            accountName: "AccountStore/accountName",
        }),
    },
    methods: {
        requestEdit() {
            this.$emit("requestEdit");
        },
        requestReplyInputFocus() {
            this.$emit("requestReplyInputFocus");
        },
        requestDelete() {
            this.$emit("requestDelete");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox_reply__account_image {
    margin-right: 24px;
    flex-shrink: 0;
}
.inbox_reply {
    display: flex;
    flex-direction: column;
    padding-top: 36px;
    padding-bottom: 4px;
    border-top: 1px solid $grey_athens;
}
.inbox_reply__label {
    @extend %headline6_style;
    margin-top: 0;
    margin-bottom: 32px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}
.inbox_reply__content {
    display: flex;
    margin-bottom: 8px;
}
.inbox_reply__bubble {
    @extend %body1_style;
    background: $white;
    border-radius: 0 18px 18px 18px;
    padding: 16px;
    border: 1px solid $grey_mischka;
    white-space: pre-wrap;
    word-break: break-word;
    hyphens: auto;
    width: fit-content;
}
.inbox_reply__edit_button {
    display: inline-block;
    color: $grey_french;
    &:hover {
        color: $grey_shark;
        cursor: pointer;
    }
}
.question_holder__actions {
    margin: 10px 16px;
    display: flex;
    gap: 14px;
    font-size: 12px;
}
.question_holder__action_btn {
    color: $grey_bombay;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        color: $black;
    }
}
</style>
