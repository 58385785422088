<template>
    <Tray
        :desktopWidth="380"
        targetElementSelector="#inbox_filter_button"
        :open-percentage="trayOpenPercentage"
        @openPercentageChangeRequest="onOpenPercentageChangeRequest"
        :left="left"
        :pointerPosition="50"
    >
        <form class="inbox_filters">
            <div class="inbox_filters__buttons">
                <Button
                    class="inbox_filters__button"
                    buttonStyle="white"
                    @click.native="
                        clearFilters();
                        closeFilter();
                    "
                >
                    {{ $t("cp__inbox__filters__buttons_clear") }}
                </Button>
                <Button
                    class="inbox_filters__button inbox_filters__button--last"
                    @click.native="applyFilters"
                >
                    {{ $t("general__apply") }}
                </Button>
            </div>
            <div class="inbox_unread_switch">
                <legend class="inbox_filters__title">
                    {{ $t("cp__inbox__filters__only_unread") }}
                </legend>
                <SwitchInput v-model="localSort.onlyUnread" />
            </div>
            <div class="inbox_filters__category">
                <legend class="inbox_filters__title">
                    {{ $t("cp__general__sort_by") }}
                </legend>
                <Dropdown
                    v-model="localSort.sortBy"
                    :placeholder="$t('cp__general__sort_by')"
                    :options="sortOptions"
                />
            </div>
            <div v-if="filteredTypes.length > 1" class="inbox_filters__category">
                <legend class="inbox_filters__title">
                    {{ $t("cp__inbox__filters__category__types_title") }}
                </legend>
                <div class="inbox_filters__types">
                    <SelectButton
                        v-for="type in filteredTypes"
                        :key="`inbox_type_select_${type.id}`"
                        v-model="type.enabled"
                    >
                        <font-awesome-icon class="select_button__icon" :icon="['far', type.icon]" />
                        {{ $t(type.name) }}
                    </SelectButton>
                </div>
            </div>
            <div class="inbox_filters__category">
                <legend class="inbox_filters__title">
                    {{ $t("cp__inbox__filters__category__types_rating") }}
                </legend>
                <NumberRangeSlider v-model="localSort.ratingRange" :from="1" :to="10" />
            </div>
            <div class="inbox_filters__category">
                <legend class="inbox_filters__title">
                    {{ $t("cp__inbox__status_title") }}
                </legend>
                <FilterCheckboxRow
                    v-for="statusOption in statusOptions"
                    :key="statusOption.value"
                    v-model="localSort[statusOption.value]"
                    class="inbox_filters__checkbox_row"
                    :labelTrue="statusOption.labelTrue"
                    :labelFalse="statusOption.labelFalse"
                    :disabled="statusOption.disabled"
                    :hidden="statusOption.hidden"
                />
            </div>
            <div class="inbox_filters__category">
                <legend class="inbox_filters__title">
                    {{ $t("cp__inbox__filters__category__types_date_range") }}
                </legend>
                <DateInput
                    v-model="localSort.dateRange"
                    :placeholder="$t('inputs__date_range__select_dates')"
                    isRange
                    :locale="getLanguageCultureCode"
                    :maxDate="new Date()"
                />
            </div>
        </form>
    </Tray>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStore, faShoppingBag, faCommentAltLines } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DateInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/DateInput.vue";
import SwitchInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/SwitchInput.vue";
import animateXtoY from "@feedbackcompany/feedback-company-vue-components/src/helpers/animationHelper";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import Dropdown from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Dropdown.vue";
import Tray from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/Tray.vue";
import { format } from "date-fns";
import FilterCheckboxRow from "@/components/Inbox/Atoms/FilterCheckboxRow.vue";
import SelectButton from "@/components/Inbox/Atoms/SelectButton.vue";
import NumberRangeSlider from "@/components/Inbox/Atoms/NumberRangeSlider.vue";

library.add(faStore, faShoppingBag, faCommentAltLines);

export default {
    name: "InboxFilters",
    components: {
        FontAwesomeIcon,
        Tray,
        DateInput,
        Button,
        Dropdown,
        SelectButton,
        NumberRangeSlider,
        FilterCheckboxRow,
        SwitchInput,
    },
    data() {
        return {
            left: 0,
            localSort: {
                dateRange: { start: null, end: null },
                types: {
                    merchant: {
                        id: "merchant",
                        package: "companyReviews",
                        name: "cp__inbox__filter__type__company",
                        icon: "store",
                        enabled: false,
                    },
                    product: {
                        id: "product",
                        package: "productReviews",
                        name: "cp__inbox__filter__type__product",
                        icon: "shopping-bag",
                        enabled: false,
                    },
                    question: {
                        id: "question",
                        package: "productQuestions",
                        name: "cp__inbox__filter__type__question",
                        icon: "comment-alt-lines",
                        enabled: false,
                    },
                },
                ratingRange: {
                    from: 1,
                    to: 10,
                },
                sortBy: "createDate_DESC",
                isRead: null,
                isReplied: null,
                isRevised: null,
                isAnswered: null,
                isPublished: null,
                hasUploadedImage: null,
                onlyUnread: false,
            },
        };
    },
    computed: {
        ...mapState({
            trayOpenPercentage: (state) => state.InboxStore.filterTrayOpenPercentage,
            sorting: (state) => state.InboxStore.sorting,
        }),
        ...mapGetters({
            sortingFiltered: "InboxStore/sortingFiltered",
            allowedReviewTypes: "NotificationStore/allowedReviewTypes",
            getLanguageCultureCode: "LanguageStore/getLanguageCultureCode",
        }),
        sortOptions() {
            return [
                {
                    value: "rating_ASC",
                    displayValue: this.$t("cp__inbox__sort__rating_asc"),
                },
                {
                    value: "rating_DESC",
                    displayValue: this.$t("cp__inbox__sort__rating_desc"),
                },
                {
                    value: "createDate_DESC",
                    displayValue: this.$t("cp__inbox__sort__create_date_desc"),
                },
                {
                    value: "createDate_ASC",
                    displayValue: this.$t("cp__inbox__sort__create_date_asc"),
                },
            ];
        },
        statusOptions() {
            const options = [
                {
                    value: "isPublished",
                    labelTrue: this.$t("cp__inbox__status__approved"),
                    labelFalse: this.$t("cp__inbox__status__on_hold"),
                },
                {
                    value: "isAnswered",
                    labelTrue: this.$t("cp__inbox__status__answered"),
                    labelFalse: this.$t("cp__inbox__status__not_answered"),
                    hidden: !this.questionTypeAllowed,
                    disabled: !this.questionTypeEnabled && this.enabledTypes.length > 0,
                },
                {
                    value: "isReplied",
                    labelTrue: this.$t("cp__inbox__status__replied"),
                    labelFalse: this.$t("cp__inbox__status__not_replied"),
                    disabled: this.questionTypeEnabled && this.enabledTypes.length < 2,
                },
                {
                    value: "isRevised",
                    labelTrue: this.$t("cp__inbox__status__revised"),
                    labelFalse: this.$t("cp__inbox__status__not_revised"),
                    disabled:
                        (this.questionTypeEnabled && this.enabledTypes.length < 2) ||
                        (this.productTypeEnabled && !this.merchantTypeEnabled),
                },
            ];
            options.sort((a, b) => a.order - b.order);
            return options;
        },
        enabledTypes() {
            // Get array of enabled types
            return Object.values(this.localSort.types)
                .filter((type) => type.enabled)
                .map((type) => type.id);
        },
        // Adapted FOR Store : Component in to Store
        sortingAdapterForStore() {
            let fromDate = null;
            let toDate = null;
            if (
                this.localSort.dateRange &&
                this.localSort.dateRange.start &&
                this.localSort.dateRange.end
            ) {
                fromDate = format(this.localSort.dateRange.start, "yyyy-MM-dd");
                toDate = format(this.localSort.dateRange.end, "yyyy-MM-dd");
            }
            const fromRating = this.localSort.ratingRange.from;
            const toRating = this.localSort.ratingRange.to;
            if (!this.localSort.onlyUnread) this.localSort.onlyUnread = null;

            const {
                isPublished,
                isAnswered,
                isRead,
                isReplied,
                isRevised,
                hasUploadedImage,
                onlyUnread,
            } = this.localSort;
            const sortByData = this.localSort.sortBy?.split("_");
            const sortBy = sortByData && sortByData.length > 1 ? sortByData[0] : "createDate";
            const sortOrder = sortByData.length > 1 ? sortByData[1] : "DESC";

            const localSorting = {
                ...this.sortingFiltered,
                fromDate,
                toDate,
                type: this.enabledTypes.join(),
                fromRating,
                toRating,
                sortBy,
                sortOrder,
                isRead,
                isReplied,
                isRevised,
                isPublished,
                isAnswered,
                hasUploadedImage,
                onlyUnread,
            };
            Object.keys(localSorting).forEach((key) => {
                if (
                    localSorting[key] === null ||
                    localSorting[key] === undefined ||
                    localSorting[key] === "" ||
                    localSorting[key] === "undefined"
                ) {
                    delete localSorting[key];
                }
            });
            return localSorting;
        },
        filteredTypes() {
            const typeFilter = ({ type }) => this.allowedReviewTypes.indexOf(type.package) > -1;
            return Object.values(this.localSort.types).filter((type) => typeFilter({ type }));
        },
        questionTypeAllowed() {
            return this.filteredTypes.filter((type) => type.id === "question").length > 0;
        },
        questionTypeEnabled() {
            return this.enabledTypes.indexOf("question") !== -1;
        },
        productTypeEnabled() {
            return this.enabledTypes.indexOf("product") !== -1;
        },
        merchantTypeEnabled() {
            return this.enabledTypes.indexOf("merchant") !== -1;
        },
    },
    methods: {
        ...mapMutations({
            setTrayOpenPercentage: "InboxStore/setFilterTrayOpenPercentage",
            setSorting: "InboxStore/setSorting",
        }),
        ...mapActions({
            setSortingParam: "InboxStore/setSortingParam",
            clearFilters: "InboxStore/clearFilters",
            clearSorting: "InboxStore/clearSorting",
        }),
        // API is different than component object, hence mapping is needed
        mapStoreSortToLocalSort() {
            // Date Range
            const { toDate } = this.sorting;
            const { fromDate } = this.sorting;
            this.localSort.dateRange = [];
            this.localSort.dateRange.start = fromDate ? new Date(fromDate) : null;
            this.localSort.dateRange.end = toDate ? new Date(toDate) : null;
            // Statusses
            const { isReplied, isRevised, isPublished, isAnswered, hasUploadedImage, onlyUnread } =
                this.sorting;
            this.localSort = {
                ...this.localSort,
                isReplied,
                isRevised,
                isPublished,
                isAnswered,
                hasUploadedImage,
                onlyUnread,
            };
            // Types
            if (this.sorting.type) {
                this.sorting.type.split(",").forEach((type) => {
                    this.localSort.types[type].enabled = true;
                });
            } else {
                Object.keys(this.localSort.types).forEach((type) => {
                    this.localSort.types[type].enabled = false;
                });
            }
            // Rating Range
            this.localSort.ratingRange = {
                from: this.sorting.fromRating,
                to: this.sorting.toRating,
            };
            // Sort by
            this.localSort.sortBy = `${this.sorting.sortBy}_${this.sorting.sortOrder}`;
        },
        onOpenPercentageChangeRequest({ percentage }) {
            this.setTrayOpenPercentage({
                openPercentage: percentage,
            });
        },
        applyFilters() {
            this.setSorting({
                sorting: this.sortingAdapterForStore,
            });
            this.closeFilter();
        },
        closeFilter() {
            if (window.innerWidth < 1001) {
                animateXtoY({
                    x: this.trayOpenPercentage,
                    y: 0,
                    time: 200,
                    easeName: "easeOutQuad",
                    executable: ({ setTo }) => {
                        this.setTrayOpenPercentage({
                            openPercentage: setTo,
                        });
                    },
                });
            } else {
                this.setTrayOpenPercentage({
                    openPercentage: 0,
                });
            }
        },
    },
    watch: {
        trayOpenPercentage() {
            const bellEl = document.getElementsByClassName("inbox__items")[0];
            const filterButton = document.getElementsByClassName("inbox_items_header__button")[0];
            this.left = bellEl.offsetWidth + filterButton.offsetWidth / 2;
        },
        sorting: {
            deep: true,
            handler() {
                this.mapStoreSortToLocalSort();
            },
        },
    },
    mounted() {
        this.mapStoreSortToLocalSort();
    },
    beforeDestroy() {
        this.clearSorting();
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox_filters {
    width: 100%;
    padding: 22px;
}
.inbox_filters__types {
    display: flex;
    justify-content: space-between;
    .select_button {
        min-width: 104px;
        height: 80px;
        padding: 0;
        flex-direction: column;
        justify-content: center;
        &:not(:first-child) {
            margin-left: 10px;
        }
        .select_button__icon {
            margin-top: 8px;
            font-size: 24px;
        }
        .select_button__name {
            margin-top: 4px;
            font-size: 14px;
            font-weight: 600;
        }
    }
}
.inbox_filters__title {
    @extend %body1_style;
    font-weight: 700;
    margin-bottom: 12px;
}
.inbox_filters__category {
    margin-bottom: 16px;
}
.inbox_filters__checkbox_row {
    display: inline-block;
}
.inbox_filters__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    margin-bottom: 20px;
}
.inbox_filters__button--last {
    margin-left: auto;
}

.inbox_unread_switch {
    margin: 20px 0px;
}
</style>
