<template>
    <div class="question">
        <h6 class="question__question">{{ question.question }}</h6>
        <div class="question__answer_holder">
            <p
                v-if="displayType === 'string'"
                class="question_answer_string question_answer_string__formatted"
            >
                {{ question.value ? question.value : "N.A." }}
            </p>
            <p v-if="displayType === 'boolean'" class="question_answer_string">
                <span v-if="question.value === true">{{ $t("ja") }}</span>
                <span v-else-if="question.value === false">{{ $t("nee") }}</span>
                <span v-else>{{ question.value }}</span>
            </p>
            <div v-if="displayType === 'list'">
                <span v-if="!question.value || question.value === 'N.A.'">N.A.</span>
                <ul v-else class="question_answer_string">
                    <li
                        v-for="item in question.value.split(',')"
                        :key="`${question.question}_${item}`"
                        class=""
                    >
                        {{ item }}
                    </li>
                </ul>
            </div>
            <div v-if="displayType === 'score'" class="question_answer_score">
                <Stars
                    class="question_answer_score__stars"
                    :rating="question.value * 2"
                    :size="20"
                />
                <div class="question_answer_score__score">{{ question.value }}/5</div>
            </div>
        </div>
    </div>
</template>

<script>
import Stars from "@/components/Inbox/Atoms/Stars.vue";

export default {
    name: "InboxCompanyQuestion",
    components: {
        Stars,
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
    },
    computed: {
        displayType() {
            const { question } = this;
            let displayType = "string";
            if (question.type.indexOf("score") > -1) displayType = "score";
            if (question.type === "multiplechoice") displayType = "list";
            if (["contact_me", "recommend_radio"].indexOf(question.type) > -1)
                displayType = "boolean";
            return displayType;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
    padding: 0px;
}
.question__question {
    @extend %body1_style;
    font-size: 0.9375em; // 15px
    font-weight: 600;
}
.question_answer_string__formatted {
    white-space: pre-wrap;
    word-break: break-word;
    hyphens: auto;
}
.question__answer_holder {
    @extend %body2_style;
    margin-top: 4px;
}
.question_answer_score {
    display: flex;
    align-items: center;
}
.question_answer_score__score {
    margin-left: 8px;
}
</style>
