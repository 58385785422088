<template>
    <div class="product_review_detail_holder">
        <InboxNavigation :isMobile="isOverlay" />
        <InboxDetailLoader v-if="loading" />
        <InboxDetailError v-else-if="hasError" :errors="review.error" />
        <InboxGrid
            v-else
            class="product_review"
            :class="{
                'product_review--is-single-column': isSingleColumn,
            }"
            :isSingleColumn="isSingleColumn"
            :isOverlay="isOverlay"
            :ID="ID"
        >
            <template v-slot:header>
                <ProductBlock
                    v-if="isSingleColumn"
                    class="product_review__product_block product_review__product_block--single_column"
                    :review="review"
                />
                <header class="product_review__header">
                    <div class="product_review__author">
                        <h1 class="product_review__author_name">
                            {{ review.customer.fullname }}
                        </h1>
                        <h5 class="product_review__author_user_email">
                            {{ review.customer.email }}
                        </h5>
                    </div>
                </header>
                <section class="product_review__review">
                    <h5 class="product_review__review_title">
                        {{ review.title }}
                    </h5>
                    <p class="product_review__review_text">{{ review.text }}</p>
                </section>
                <FinalScore :rating="review.rating" :customLabel="$t('general__final_score')" />
                <InboxReply
                    v-if="hasResponse && !editingResponse"
                    class="product_review__response"
                    @requestEdit="editingResponse = true"
                    @requestReplyInputFocus="focusReplyInput"
                    @requestDelete="confirmDelete"
                    :showEditButton="false"
                    :reply="review.shop_comment"
                />
                <hr class="product_review__page_break" />
            </template>
            <template v-slot:aside>
                <StatusIndicatorRow
                    :compact="true"
                    :onHold="false"
                    :published="true"
                    :daysLeft="0"
                    :publicationDate="review.create_date"
                    :maxDays="1"
                />
                <InboxProductDetailTable :ID="ID" :compact="!isSingleColumn" />
                <ProductBlock
                    v-if="!isSingleColumn"
                    class="product_review__product_block"
                    :review="review"
                />
                <InboxProductActionBlock
                    v-if="!isSingleColumn"
                    :ID="ID"
                    @requestReplyInputFocus="focusReplyInput"
                    class="product_review_detail__action_block"
                />
            </template>
            <template v-slot:main>
                <h1 class="inbox__sub_title product_review__perks_cons__title">
                    {{ $t("cp__inbox__product__pros_and_cons__title") }}
                </h1>
                <section
                    v-if="hasProsAndOrCons"
                    class="product_review__perks_cons"
                    :class="{
                        'product_review__perks_cons--single_column': isSingleColumn,
                    }"
                >
                    <section class="perks_cons perks_cons--perks">
                        <h4 class="perks_cons__title">
                            {{ $t("cp__inbox__product__pros_cons__pros_title") }}
                        </h4>
                        <ul v-if="review.positives.length > 0">
                            <li
                                v-for="(point, index) in review.positives"
                                class="perks_cons__point perks_cons__point--perk"
                                :key="`${ID}_positive_${index}_${point}`"
                            >
                                <span class="perks_cons__text_color_reset">{{ point }}</span>
                            </li>
                        </ul>
                        <div v-else class="perks_cons__empty">
                            {{ $t("cp__inbox__product__pros_cons__pros_empty") }}
                        </div>
                    </section>
                    <section class="perks_cons perks_cons--cons">
                        <h4 class="perks_cons__title">
                            {{ $t("cp__inbox__product__pros_cons__cons_title") }}
                        </h4>
                        <ul v-if="review.negatives.length > 0">
                            <li
                                v-for="(point, index) in review.negatives"
                                class="perks_cons__point perks_cons__point--con"
                                :key="`${ID}_negative_${index}_${point}`"
                            >
                                <span class="perks_cons__text_color_reset">{{ point }}</span>
                            </li>
                        </ul>
                        <div v-else class="perks_cons__empty">
                            {{ $t("cp__inbox__product__pros_cons__cons_empty") }}
                        </div>
                    </section>
                </section>
                <section v-else class="no_pros_cons">
                    <div class="no_pros_cons__face">
                        <font-awesome-icon class="no_pros_cons__face_icon" :icon="['fal', 'meh']" />
                    </div>
                    <span class="no_pros_cons__text">
                        {{ $t("cp__inbox__product__pros_cons__empty") }}
                    </span>
                </section>
                <hr
                    v-if="review.uploaded_image_url.length > 0 && !isSingleColumn"
                    class="product_review__page_break"
                />
                <section
                    v-if="review.uploaded_image_url.length > 0"
                    class="product_review__user_image"
                >
                    <h4 class="inbox__sub_title product_review__user_image_title">
                        {{ $t("cp__inbox__product__user_image__title") }}
                    </h4>
                    <img
                        alt="Image"
                        class="product_review__user_image_image"
                        data-zoomable
                        :src="review.uploaded_image_url"
                    />
                </section>
            </template>
        </InboxGrid>
        <InboxProductActionBlock
            v-if="!loading && !hasError && isSingleColumn"
            :ID="ID"
            @requestReplyInputFocus="focusReplyInput"
            class="product_review_detail__action_block product_review_detail__action_block--fixed"
            :class="{
                'product_review_detail__action_block--open': actionBlockOpenMobile,
                has_response: response,
            }"
        />
        <!-- Only shown by default on smaller screens (singleColumn). On bigger screens user has to set this part active
        by clicking ActionBlock and set responseInputOpen to: true -->
        <InboxDetailActionBar
            v-show="isSingleColumn || responseInputOpen"
            class="action_bar"
            :isSingleColumn="isSingleColumn"
            :hideSideActions="isSingleColumn && responseInputOpen"
        >
            <template #default>
                <InboxResponseInput
                    v-if="showResponseInput"
                    v-model="response"
                    ref="replyInput"
                    :isSmall="isSingleColumn && isOverlay"
                    :isLoading="review.replySubmitting"
                    :ID="ID"
                    :onHold="onHold"
                    :hasResponse="hasResponse"
                    :hasUnsavedResponse="hasUnsavedResponse"
                    :isSingleColumn="isSingleColumn"
                    @submitRequest="submitReply"
                    @requestMoreSpace="setResponseInputOpen"
                    @requestLessSpace="setResponseInputClosed"
                    :maxLength="2000"
                />
            </template>
            <template #single_action>
                <InboxHamburgerButton
                    @click.native="toggleActionBlockOpenMobile"
                    :toggled="actionBlockOpenMobile"
                />
            </template>
        </InboxDetailActionBar>
        <ConfirmPopup
            v-if="showReplyDeletePopup"
            :title="$t('cp__generic__warning')"
            :message="$t('cp__inbox__reply__delete_message')"
            :confirm-btn="$t('cp__generic__delete')"
            confirmButtonStyle="negative"
            @confirm="deleteReply"
            @cancel="closeConfirmDialog"
            :confirmBtnIsLoading="deleteInProgress"
        />
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { vueWindowSizeMixin } from "vue-window-size";
import ConfirmPopup from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/ConfirmPopup.vue";
import mediumZoom from "medium-zoom";
import InboxDetailLoader from "@/components/Inbox/Molecules/InboxDetailLoader.vue";
import InboxDetailError from "@/components/Inbox/Molecules/InboxDetailError.vue";
import FinalScore from "@/components/Inbox/Molecules/FinalScore.vue";
import InboxResponseInput from "@/components/Inbox/Molecules/InboxResponseInput.vue";
import InboxReply from "@/components/Inbox/Molecules/InboxReply.vue";
import InboxDetailActionBar from "@/components/Inbox/Molecules/InboxDetailActionBar.vue";
import InboxGrid from "@/components/Inbox/Molecules/InboxGrid.vue";
import InboxProductDetailTable from "@/components/Inbox/Molecules/InboxProductDetailTable.vue";
import InboxNavigation from "@/components/Inbox/Molecules/InboxNavigation.vue";
import StatusIndicatorRow from "@/components/Inbox/Molecules/ReviewStatusIndicatorRow.vue";
import InboxHamburgerButton from "@/components/Inbox/Atoms/InboxHamburgerButton.vue";
import ProductBlock from "@/components/Inbox/Molecules/ProductBlock.vue";
import InboxProductActionBlock from "@/components/Inbox/Molecules/InboxProductActionBlock.vue";
import deleteReviewReply from "@/graphql/inbox/deleteReviewReply.gql";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMeh } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faMeh);

export default {
    name: "InboxDetailProduct",
    mixins: [vueWindowSizeMixin],
    components: {
        InboxDetailLoader,
        InboxDetailError,
        FinalScore,
        InboxNavigation,
        InboxResponseInput,
        InboxReply,
        InboxDetailActionBar,
        InboxGrid,
        InboxProductDetailTable,
        StatusIndicatorRow,
        InboxHamburgerButton,
        ProductBlock,
        InboxProductActionBlock,
        ConfirmPopup,
        FontAwesomeIcon,
    },
    props: {
        ID: {
            required: true,
        },
        isSingleColumn: {
            type: Boolean,
            default: false,
        },
        isOverlay: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            response: "",
            editingResponse: false,
            imagesZoomable: false,
            responseInputOpen: false,
            actionBlockOpenMobile: false,
            showReplyDeletePopup: false,
            deleteInProgress: false,
        };
    },
    computed: {
        ...mapState({
            locale: (state) => state.LanguageStore.currentLanguage,
            companyReviewOpenPopups: (state) => state.ProductReviewStore.Popups.open,
        }),
        ...mapGetters({
            getReviewByID: "ProductReviewStore/getReviewByID",
            activeAccount: "AccountStore/activeAccount",
        }),
        review() {
            return this.getReviewByID(this.ID);
        },
        loading() {
            return this.review === undefined || this.review.loading;
        },
        hasError() {
            return this.review.hasError;
        },
        openPopups() {
            return this.companyReviewOpenPopups
                .filter((popup) => popup.indexOf(this.review.ID) > -1)
                .map((popup) => ({
                    ID: popup,
                    type: popup.split(`_${this.review.ID}`)[0],
                }))
                .filter((popup) => popup.type !== "reply_confirmation_popup");
        },
        topPopup() {
            return this.openPopups[this.openPopups.length - 1];
        },
        mainQuestion() {
            return this.review.questions.find((question) => question.type === "main_open").value;
        },
        hasResponse() {
            return !!this.review.shop_comment;
        },
        hasUnsavedResponse() {
            return this.hasResponse && this.review.shop_comment !== this.response;
        },
        subscores() {
            return this.review.questions
                .filter((question) => question.type !== "main_open")
                .map((question) => {
                    if (
                        (question.type === "score" && question.value === "-1") ||
                        (question.type === "open" && question.value === "")
                    ) {
                        question.value = "-";
                    }
                    return question;
                });
        },
        onHold() {
            return this.review.status === "on hold";
        },
        responsePortalName() {
            let location = "desktop";
            if (this.isSingleColumn) location = "mobile";
            return `response_input_portal--${location}`;
        },
        hasProsAndOrCons() {
            return this.review.negatives.length + this.review.positives.length > 0;
        },
        showResponseInput() {
            return this.activeAccount.permissions.products.newProductReviewsEnabled;
        },
    },
    watch: {
        ID() {
            this.IDChangeHandler();
        },
        review(newReview, oldReview) {
            this.response = newReview.shop_comment;
            if (!newReview && oldReview !== undefined) {
                this.getReview({ ID: this.ID });
            }
            if (this.review === undefined || this.review.loading || this.imagesZoomable) return;
            this.imagesZoomable = true;
            this.setZoomableImages();
        },
    },
    methods: {
        ...mapMutations({
            openPopup: "ProductReviewStore/Popups/open",
        }),
        ...mapActions({
            getReview: "ProductReviewStore/get",
            reply: "ProductReviewStore/reply",
            setRead: "ProductReviewStore/setRead",
        }),
        setZoomableImages() {
            window.requestAnimationFrame(() => {
                const zoomableImages = document.querySelectorAll("[data-zoomable]");
                mediumZoom(zoomableImages);
            });
        },
        submitReply({ reply }) {
            this.editingResponse = false;
            this.reply({ ID: this.ID, reply });
        },
        async IDChangeHandler() {
            this.getReview({ ID: this.ID });
            await this.setRead({ ID: this.ID, isRead: true });
            this.response = "";
        },
        markUnreadHandler() {
            this.setRead({ ID: this.ID, isRead: false });
        },
        setResponseInputOpen() {
            this.responseInputOpen = true;
            this.response = this.review.shop_comment;
        },
        setResponseInputClosed() {
            this.responseInputOpen = false;
        },
        focusReplyInput() {
            this.$refs.replyInput.focus();
        },
        toggleActionBlockOpenMobile() {
            this.actionBlockOpenMobile = !this.actionBlockOpenMobile;
        },
        confirmDelete() {
            this.showReplyDeletePopup = true;
        },
        closeConfirmDialog() {
            this.showReplyDeletePopup = false;
            this.deleteInProgress = false;
        },
        async deleteReply() {
            try {
                this.deleteInProgress = true;
                await this.$apollo.mutate({
                    mutation: deleteReviewReply,
                    variables: {
                        reviewId: this.ID,
                        reviewType: "PRODUCT",
                    },
                    context: {
                        headers: {
                            "X-Shop-Uuid": this.$store.state.AccountStore.shopData.externalUuid,
                        },
                    },
                });
                await this.$store.dispatch("pushNotification", {
                    type: "success",
                    title: this.$t("cp__generic__success"),
                    message: this.$t("cp__inbox__reply__delete_success_message"),
                });
                this.IDChangeHandler();
            } catch (error) {
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__generic__error_title"),
                    message: this.$t("cp__inbox__reply__delete_error_message"),
                });
            }
            this.closeConfirmDialog();
        },
    },
    mounted() {
        this.IDChangeHandler();
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
    padding: 0px;
}
[data-zoomable] {
    z-index: 10;
}
.medium-zoom-image--opened {
    box-shadow: 0px 0px 250px 0px rgba(#ececec, 1);
}

.product_review_detail_holder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.product_review {
    height: 100%;
}
.product_review__page_break {
    width: 100%;
    border: 0px;
    border-top: 1px solid;
    border-color: $grey_alabaster;
    margin: 36px 0 0px;
}
.product_review__header {
    display: flex;
    align-items: center;
}
.product_review__author_name {
    @extend %headline5_style;
    margin: 0px;
    line-height: 24px;
}
.product_review__author_user_email {
    @extend %body1_style;
    color: $grey_mid;
    line-height: 18px;
    margin-top: 12px;
}

.product_review__review_title {
    @extend %body1_style;
    position: relative;
    font-weight: 600;
    margin: 24px 0 8px;
}
.product_review__review_text {
    position: relative;
    display: inline-block;
    margin: 12px 0 24px;
    white-space: pre-wrap;
    word-break: break-word;
    hyphens: auto;
}
.product_review__response {
    margin-top: 24px;
}
.product_review__perks_cons {
    display: flex;
    ul {
        padding-left: 24px;
    }
}
.product_review__perks_cons--single_column {
    display: inline-block;
    .perks_cons {
        max-width: calc(100% - 36px);
    }
    .perks_cons--cons {
        margin: 32px 0 0;
    }
}
.inbox__sub_title {
    @extend %headline6_style;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}
.perks_cons {
    min-width: 200px;
    max-width: 300px;
}
.product_review__perks_cons__title {
    margin: 0px 0 16px;
}
.perks_cons__title * {
    display: inline-block;
}
.perks_cons__title svg {
    margin-right: 4px;
}
.perks_cons__title {
    @extend %body2_style;
    font-size: 0.9375em; // 15px
    color: $black;
    font-weight: 600;
}
.perks_cons--cons {
    margin-left: 16px;
}
.perks_cons__point {
    margin-bottom: 16px;
    font-size: 14px;
}
.perks_cons__point--con {
    color: $red;
}
.perks_cons__text_color_reset {
    color: $black;
}
.perks_cons__point--perk {
    color: $green;
}
.perks_cons__empty {
    color: $grey_french;
    line-height: 28px;
    margin-top: 12px;
}

.no_pros_cons {
    margin: 32px 0;
    text-align: center;
    color: $grey_french;
    &__face {
        font-size: 38px;
        margin-bottom: 16px;
    }
    &__text {
        line-height: 28px;
    }
}

.product_review__user_image_title {
    margin-bottom: 8px;
}
.product_review__user_image_image {
    margin-top: 12px;
    max-width: 500px;
    max-height: 300px;
}
.action_bar {
    width: 100%;
    bottom: 0px;
    z-index: 3;
    border-top: 1px solid $grey_athens;
}
.action_bar--overlay {
    width: calc(100% + 12px);
    margin-left: -6px;
}
.action_bar__loader {
    margin-left: 8px;
}
.product_review__product_block {
    margin: 24px 0px;
}
.product_review__product_block--single_column {
    margin: 12px 0 24px;
}
.product_review_detail__action_block {
    position: sticky;
    top: -16px;
}
.product_review_detail__action_block--fixed {
    position: fixed;
    transform: translate3d(0, calc(100% + 16px), 0);
    bottom: 92px;
    width: calc(var(--inbox_detail_width) - 16px);
    top: auto;
    right: 8px;
    margin: 0px;
    z-index: 2;
    transition: transform 200ms;

    &.has_response {
        bottom: 102px;
    }
}
.product_review_detail__action_block--open {
    transform: translate3d(0, 0%, 0);
}
// Mobile
.product_review.product_review--is-single-column {
    grid-gap: 16px;
    padding: 16px 16px;

    .product_review__header_image {
        margin-right: 16px;
        width: 48px;
        height: 48px;
    }
    .product_review__author_product_name {
        font-size: 16px;
    }
    .product_review__review_title {
        &:before {
            content: "";
        }
    }
    .product_review__review_text {
        &:after {
            content: "";
        }
    }
    .product_review__user_image_image {
        max-width: calc(100% - 32px);
    }
    .product_review__user_image {
        margin-bottom: 16px;
    }
}
</style>
