<template>
    <div class="company_review_detail_holder">
        <InboxNavigation :isMobile="isOverlay" />
        <InboxDetailLoader v-if="loading" />
        <InboxDetailError v-else-if="hasError" :errors="review.error" />
        <InboxGrid
            v-else
            class="company_review_detail"
            :isSingleColumn="isSingleColumn"
            :isOverlay="isOverlay"
            :ID="ID"
        >
            <template v-slot:header>
                <ConfirmPopup
                    v-if="topPopup && topPopup.type === 'publish_confirm_popup'"
                    :popupID="`publish_confirm_popup_${ID}`"
                    :title="$t('cp__inbox__publish_confirm__title')"
                    :message="$t('cp__inbox__publish_confirm__message')"
                    :confirm-btn="$t('cp__inbox__publish_button_label')"
                    :cancel-btn="$t('cp__generic__cancel')"
                    @confirm="submitApprove"
                    @cancel="closePublishConfirmPopup"
                />
                <Popup
                    v-else-if="topPopup"
                    :popupID="topPopup.ID"
                    class="popup"
                    @close="closeRevisePopup"
                >
                    <InboxCompanyRevise
                        v-if="topPopup.type === 'revise_popup'"
                        :ID="ID"
                        @cancel="closeRevisePopup"
                    />
                </Popup>
                <span v-else class="event_listeners">
                    <EventListener @keyup.t="openRevisePopup" />
                    <EventListener @keyup.r="focusReplyInput" />
                    <EventListener @keyup.esc="editingResponse = false" />
                </span>
                <main class="company_review_detail__main">
                    <main class="company_review_detail__status_and_address">
                        <address class="company_review_detail__address">
                            <h1 class="company_review_detail__customer_name">
                                {{ review.customer.name }}
                            </h1>
                            <p class="company_review_detail__customer_email">
                                {{ review.customer.email }}
                            </p>
                        </address>
                        <StatusIndicatorRow
                            v-if="isOverlay && isSingleColumn"
                            :iconOnly="true"
                            :onHold="onHold"
                            :daysLeft="review.onHoldDaysRemaining"
                            :publicationDate="review.publish_date"
                            :maxDays="activeAccount.onHoldPeriod"
                        />
                    </main>
                    <p class="company_review_detail__main_review_text">{{ mainQuestion }}</p>
                    <FinalScore
                        :rating="review.final_score * 2"
                        :customLabel="finalScoreQuestion"
                    />
                    <InboxReply
                        v-if="hasResponse && !editingResponse"
                        class="company_review_detail__response"
                        @requestEdit="editingResponse = true"
                        @requestReplyInputFocus="focusReplyInput"
                        @requestDelete="confirmDelete"
                        :showEditButton="false"
                        :reply="review.shop_comment"
                    />
                </main>
            </template>
            <template v-slot:aside>
                <aside v-if="!loading && !hasError" class="company_review_detail__table">
                    <span>
                        <StatusIndicatorRow
                            :compact="true"
                            :onHold="onHold"
                            :daysLeft="review.onHoldDaysRemaining"
                            :publicationDate="review.publish_date"
                            :maxDays="activeAccount.onHoldPeriod"
                        />
                        <InboxCompanyDetailTable :ID="ID" :compact="!isSingleColumn" />
                    </span>
                    <!-- Non Single Column Action Block, in grid -->
                    <InboxCompanyActionBlock
                        v-if="!isSingleColumn"
                        :ID="ID"
                        @requestReplyInputFocus="focusReplyInput"
                        class="company_review_detail__action_block"
                    />
                </aside>
            </template>
            <template v-slot:main>
                <section class="subscores">
                    <div v-if="subscores.length > 0">
                        <h1 class="subscores__title">
                            {{ $t("cp__inbox__company__subscores") }}
                        </h1>
                        <div v-if="subscores.length > 0" class="subscores__scores">
                            <InboxCompanyQuestion
                                v-for="subscore in subscores"
                                :key="`${ID}${subscore.question_id}`"
                                :question="subscore"
                            />
                        </div>
                    </div>
                    <div v-if="questions.length > 0">
                        <h1 class="subscores__title">
                            {{ $t("general__questions") }}
                        </h1>
                        <div v-if="questions.length > 0" class="subscores__questions">
                            <InboxCompanyQuestion
                                v-for="subscore in questions"
                                :key="`${ID}${subscore.question_id}`"
                                :question="subscore"
                            />
                        </div>
                    </div>
                </section>
            </template>
            <portal-target name="response_input_portal--desktop" />
        </InboxGrid>
        <!-- Non Single Column Action Block, outside of grid, because of position fixed -->
        <InboxCompanyActionBlock
            v-if="!loading && !hasError && isSingleColumn"
            :ID="ID"
            @requestReplyInputFocus="focusReplyInput"
            class="company_review_detail__action_block company_review_detail__action_block--fixed"
            :class="{
                'company_review_detail__action_block--open': actionBlockOpenMobile,
                has_response: response,
            }"
        />
        <!-- Only shown by default on smaller screens (singleColumn). On bigger screens user has to set this part active
        by clicking ActionBlock and set responseInputOpen to: true -->
        <InboxDetailActionBar
            v-show="isSingleColumn || responseInputOpen"
            class="action_bar"
            :isSingleColumn="isSingleColumn"
            :hideSideActions="isSingleColumn && responseInputOpen"
        >
            <template #default>
                <InboxResponseInput
                    v-model="response"
                    class="company_review_detail__response_input"
                    ref="replyInput"
                    :isSmall="isSingleColumn && isOverlay"
                    :isLoading="review.replySubmitting"
                    :ID="ID"
                    :onHold="onHold"
                    :hasResponse="hasResponse"
                    :hasUnsavedResponse="hasUnsavedResponse"
                    @submitRequest="submitReply"
                    @requestMoreSpace="setResponseInputOpen"
                    @requestLessSpace="setResponseInputClosed"
                    :isSingleColumn="isSingleColumn"
                    :maxLength="4000"
                />
            </template>
            <template #single_action>
                <InboxHamburgerButton
                    @click.native="toggleActionBlockOpenMobile"
                    :toggled="actionBlockOpenMobile"
                />
            </template>
        </InboxDetailActionBar>
        <ConfirmPopup
            v-if="showReplyDeletePopup"
            :title="$t('cp__generic__warning')"
            :message="$t('cp__inbox__reply__delete_message')"
            :confirm-btn="$t('cp__generic__delete')"
            confirmButtonStyle="negative"
            @confirm="deleteReply"
            @cancel="closeConfirmDialog"
            :confirmBtnIsLoading="deleteInProgress"
        />
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { vueWindowSizeMixin } from "vue-window-size";
import Popup from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/Popup.vue";
import ConfirmPopup from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/ConfirmPopup.vue";
import InboxDetailLoader from "@/components/Inbox/Molecules/InboxDetailLoader.vue";
import InboxDetailError from "@/components/Inbox/Molecules/InboxDetailError.vue";
import FinalScore from "@/components/Inbox/Molecules/FinalScore.vue";
import StatusIndicatorRow from "@/components/Inbox/Molecules/ReviewStatusIndicatorRow.vue";
import InboxGrid from "@/components/Inbox/Molecules/InboxGrid.vue";
import InboxResponseInput from "@/components/Inbox/Molecules/InboxResponseInput.vue";
import InboxReply from "@/components/Inbox/Molecules/InboxReply.vue";
import InboxDetailActionBar from "@/components/Inbox/Molecules/InboxDetailActionBar.vue";
import InboxCompanyQuestion from "@/components/Inbox/Molecules/InboxCompanyQuestion.vue";
import InboxCompanyDetailTable from "@/components/Inbox/Molecules/InboxCompanyDetailTable.vue";
import InboxCompanyActionBlock from "@/components/Inbox/Molecules/InboxCompanyActionBlock.vue";
import InboxNavigation from "@/components/Inbox/Molecules/InboxNavigation.vue";
import EventListener from "@/components/Global/Atoms/EventListener.vue";
import InboxHamburgerButton from "@/components/Inbox/Atoms/InboxHamburgerButton.vue";
import deleteReviewReply from "@/graphql/inbox/deleteReviewReply.gql";

const InboxCompanyRevise = () =>
    import(
        /* webpackChunkName: "HTMLEditor" */ "@/components/Inbox/Molecules/InboxCompanyRevise.vue"
    );

export default {
    name: "InboxDetailCompany",
    mixins: [vueWindowSizeMixin],
    components: {
        InboxGrid,
        InboxDetailLoader,
        InboxDetailError,
        FinalScore,
        InboxNavigation,
        StatusIndicatorRow,
        InboxResponseInput,
        InboxReply,
        InboxDetailActionBar,
        InboxCompanyQuestion,
        Popup,
        ConfirmPopup,
        InboxCompanyRevise,
        InboxCompanyActionBlock,
        InboxCompanyDetailTable,
        InboxHamburgerButton,
        EventListener,
    },
    props: {
        ID: {
            required: true,
        },
        isSingleColumn: {
            type: Boolean,
            default: false,
        },
        isOverlay: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            response: "",
            editingResponse: false,
            actionBlockOpenMobile: false,
            responseInputOpen: false,
            showReplyDeletePopup: false,
            deleteInProgress: false,
        };
    },
    computed: {
        ...mapState({
            locale: (state) => state.LanguageStore.currentLanguage,
            companyReviewOpenPopups: (state) => state.CompanyReviewStore.Popups.open,
        }),
        ...mapGetters({
            getReviewByID: "CompanyReviewStore/getReviewByID",
            activeAccount: "AccountStore/activeAccount",
        }),
        review() {
            return this.getReviewByID(this.ID);
        },
        loading() {
            return this.review === undefined || this.review.loading;
        },
        hasError() {
            return this.review.hasError;
        },
        openPopups() {
            return this.companyReviewOpenPopups
                .filter((popup) => popup.indexOf(this.review.ID) > -1)
                .map((popup) => ({
                    ID: popup,
                    type: popup.split(`_${this.review.ID}`)[0],
                }))
                .filter((popup) => popup.type !== "reply_confirmation_popup");
        },
        topPopup() {
            return this.openPopups[this.openPopups.length - 1];
        },
        mainQuestion() {
            const quest = this.review.questions.find(
                (question) => question.type === "main_open"
            ).value;
            const txt = new DOMParser().parseFromString(quest, "text/html");
            return txt.documentElement.textContent;
        },
        hasResponse() {
            return !!this.review.shop_comment;
        },
        hasUnsavedResponse() {
            return this.hasResponse && this.review.shop_comment !== this.response;
        },
        subscores() {
            // Questions that are already displayed elsewhere on the page.
            const filterQuestions = [
                "final_score",
                "open",
                "main_open",
                "choice",
                "multiplechoice",
                "recommend_radio",
                "contact_me",
            ];
            return this.review.questions
                .filter((question) => filterQuestions.indexOf(question.type) < 0)
                .map((question) => {
                    if (question.type === "score" && question.value === "-1") {
                        question.value = "-";
                    }
                    return question;
                });
        },
        questions() {
            // Questions that are already displayed elsewhere on the page.
            const filterQuestions = ["final_score", "score", "main_open", "nps", "ces"];
            return this.review.questions
                .filter((question) => filterQuestions.indexOf(question.type) < 0)
                .map((question) => {
                    if (question.type === "open" && question.value === "") {
                        question.value = "-";
                    }
                    return question;
                });
        },
        finalScoreQuestion() {
            return this.review.questions.find((question) => question.type === "final_score")
                .question;
        },
        onHold() {
            return this.review.status === "on hold";
        },
    },
    watch: {
        ID() {
            this.IDChangeHandler();
        },
        review(newReview, oldReview) {
            if (typeof newReview !== "object" && oldReview !== undefined) {
                this.getReview({ ID: this.ID });
            }
        },
    },
    methods: {
        ...mapMutations({
            openPopup: "CompanyReviewStore/Popups/open",
            closePopup: "CompanyReviewStore/Popups/close",
        }),
        ...mapActions({
            getReview: "CompanyReviewStore/get",
            reply: "CompanyReviewStore/reply",
            approve: "CompanyReviewStore/approve",
            setRead: "CompanyReviewStore/setRead",
        }),
        submitReply({ reply }) {
            this.editingResponse = false;
            this.reply({ ID: this.ID, reply });
            this.IDChangeHandler();
        },
        submitApprove() {
            this.closePublishConfirmPopup();
            this.approve({ ID: this.ID });
        },
        openRevisePopup() {
            this.openPopup({ ID: `revise_popup_${this.ID}` });
        },
        closeRevisePopup() {
            this.closePopup({ ID: `revise_popup_${this.ID}` });
        },
        closePublishConfirmPopup() {
            this.closePopup({ ID: `publish_confirm_popup_${this.ID}` });
        },
        async IDChangeHandler() {
            this.getReview({ ID: this.ID });
            await this.setRead({ ID: this.ID, isRead: true });
            this.response = this.review.shop_comment;
        },
        focusReplyInput() {
            this.$refs.replyInput.focus();
        },
        toggleActionBlockOpenMobile() {
            this.actionBlockOpenMobile = !this.actionBlockOpenMobile;
        },
        setResponseInputOpen() {
            this.responseInputOpen = true;
            this.response = this.review.shop_comment;
        },
        setResponseInputClosed() {
            this.responseInputOpen = false;
        },
        confirmDelete() {
            this.showReplyDeletePopup = true;
        },
        closeConfirmDialog() {
            this.showReplyDeletePopup = false;
            this.deleteInProgress = false;
        },
        async deleteReply() {
            try {
                this.deleteInProgress = true;
                await this.$apollo.mutate({
                    mutation: deleteReviewReply,
                    variables: {
                        reviewId: this.ID,
                        reviewType: "COMPANY",
                    },
                    context: {
                        headers: {
                            "X-Shop-Uuid": this.$store.state.AccountStore.shopData.externalUuid,
                        },
                    },
                });
                await this.$store.dispatch("pushNotification", {
                    type: "success",
                    title: this.$t("cp__generic__success"),
                    message: this.$t("cp__inbox__reply__delete_success_message"),
                });
                this.IDChangeHandler();
                this.response = "";
            } catch (error) {
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__generic__error_title"),
                    message: this.$t("cp__inbox__reply__delete_error_message"),
                });
            }
            this.closeConfirmDialog();
        },
    },
    mounted() {
        this.IDChangeHandler();
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_typography_classes.scss";

$table_width: 250px;

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
    padding: 0px;
}
.company_review_detail_holder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.company_review_detail--single_column {
    .subscores {
        margin-top: 0px;
    }
    .subscores__scores {
        grid-template-columns: 100%;
    }
    .company_review_detail__table {
        max-width: 100%;
    }
}
.company_review_detail--overlay {
    padding: 16px 24px;
}
.inbox__sub_title {
    @extend %headline6_style;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}
.company_review_detail__main,
.subscores {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 24px;
    grid-auto-rows: min-content;
    min-width: 0;
}
.company_review_detail__status_and_address {
    @extend .body2;
    display: inline-flex;
}
.company_review_detail__address {
    text-transform: none;
    font-style: normal;
    flex-grow: 1;
}
.company_review_detail__customer_name {
    @extend %headline5_style;
    margin: 0 0 12px;
}
.company_review_detail__customer_email {
    @extend %body1_style;
    color: $grey_mid;
    line-height: 18px;
    margin-top: 12px;
    font-size: 16px;
}
.company_review_detail__main_review_text {
    @extend .body1;
    font-size: 16px;
    margin: 0;
    white-space: pre-wrap;
    word-break: break-word;
    hyphens: auto;
}
.company_review_detail__written_rating {
    @media (max-width: 368px) {
        margin-top: 16px;
    }
}
.company_review_detail__table {
    @extend .body2;
    width: 100%;
    max-width: $table_width;
    background: $white;
    grid-area: aside;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 24px;
    grid-auto-rows: min-content;
    min-width: 0;
}
.company_review_detail__action_block {
    position: sticky;
    top: -16px;
}

.company_review_detail__action_block--fixed {
    position: fixed;
    transform: translate3d(0, calc(100% + 16px), 0);
    bottom: 92px;
    width: calc(var(--inbox_detail_width) - 16px);
    top: auto;
    right: 8px;
    margin: 0px;
    z-index: 2;
    transition: transform 200ms;

    &.has_response {
        bottom: 102px;
    }
}
.company_review_detail__action_block--open {
    transform: translate3d(0, 0%, 0);
}
.company_review_detail__rating_indicator {
    font-size: 16px;
    font-weight: 500;
}
.company_review_detail__mark_unread_button {
    margin: 16px 16px 0 0;
}
.company_review_detail__report_button {
    margin-top: 16px;
}
.subscores {
    margin-top: 8px;
    padding-top: 36px;
    grid-area: subscores;
    border-top: 1px solid $grey_athens;
}
.subscores__title {
    @extend .headline6;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 12px;
}
.subscores__scores {
    display: grid;
    grid-template-columns: calc(50% - 12px) calc(50% - 12px);
    grid-gap: 24px;
    grid-auto-rows: min-content;
    min-width: 0;
    margin-bottom: 12px;
}
.subscores__questions {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 24px;
    grid-auto-rows: min-content;
    min-width: 0;
    margin-bottom: 96px;
}
.company_review_detail_loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.company_review_detail__response {
    margin-top: 8px;
}
.action_bar {
    width: 100%;
    bottom: 0px;
    z-index: 3;
    border-top: 1px solid $grey_athens;
}
.action_bar__loader {
    margin-left: 8px;
}
.vue-portal-target,
.company_review_detail__response_input,
.company_review_detail__response {
    max-width: 700px;
}
.popup {
    grid-area: popup;
}
.company_review_detail_holder--is_rerendering_grid {
    display: inline-block;
    opacity: 0;
    transform: translate(-1px, 0px);
}
</style>
