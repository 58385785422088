<template>
    <div class="inbox_chunk" ref="inboxChunk">
        <ul v-if="!loading && chunkData" class="inbox_chunk__items">
            <component
                v-for="inboxItem in chunkData.rows"
                :key="`${page}_${inboxItem.ID}`"
                :is="inboxItem.component"
                class="inbox_item"
                :selected="false"
                :ID="inboxItem.ID"
                :consumerName="inboxItem.author"
                :rating="inboxItem.rating"
                :date="inboxItem.createDate"
                :type="inboxItem.type"
                :read="inboxItem.isRead"
                :published="inboxItem.isPublished"
                :replied="inboxItem.isReplied"
                :revised="inboxItem.isRevised"
                :answered="inboxItem.isAnswered"
                :excerpt="inboxItem.description"
                :hasUploadedImage="inboxItem.hasUploadedImage"
                :productName="inboxItem.productName"
                :page="page"
                :isSelected="inboxItem.isSelected"
            />
        </ul>
        <div v-else-if="loaderIndex === page" class="inbox__chunk__loader">
            <LoaderCircular />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";

export default {
    name: "InboxChunk",
    components: {
        LoaderCircular,
    },
    props: {
        page: {
            type: Number,
            required: true,
        },
        scrollTop: {
            type: Number,
            default: 0,
        },
        containerOffsetTop: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapState({
            inboxChunks: (state) => state.InboxStore.inboxChunks,
        }),
        chunkData() {
            return this.inboxChunks[this.page] || null;
        },
        loading() {
            if (!this.chunkData) return false;
            return this.chunkData.loading;
        },
        loaderIndex() {
            // in case there is more the one chunk loading at the moment, display loader for page with smaller index
            const inboxChunksList = Object.values(this.inboxChunks);
            let loaderIndex = null;
            for (let i = 0; i <= inboxChunksList.length - 1; i += 1) {
                if (inboxChunksList[i].loading) {
                    loaderIndex = i + 1;
                    break;
                }
            }
            return loaderIndex;
        },
    },
    methods: {
        ...mapActions({
            getInboxItems: "InboxStore/getInboxChunk",
        }),
    },
    watch: {
        scrollTop() {
            if (!this.$refs.inboxChunk) return;
            const rect = this.$refs.inboxChunk.getBoundingClientRect();
            this.$emit("topDistanceChange", {
                page: this.page,
                rect,
                loaded: this.loading || null,
            });
        },
        loading() {
            if (this.loading) return;
            window.requestAnimationFrame(() => {
                this.$emit("onLoad", { page: this.page });
            });
        },
    },
    beforeMount() {
        if (this.page === 0) return;
        this.getInboxItems({
            page: this.page,
        });
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox__chunk__loader {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.inbox_chunk__items {
    list-style: none;
    padding: 0;
    margin: 0;
}
.inbox_chunk__no_results {
    @extend %body1_style;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
