<template>
    <InboxTable v-if="!loading" :rows="tableData" :compact="compact" :ID="ID" />
</template>

<script>
import { format } from "date-fns";
import { enGB, nl, de, es } from "date-fns/locale";
import { mapState, mapGetters } from "vuex";
import InboxTable from "@/components/Inbox/Molecules/InboxTable.vue";

const locales = {
    en: enGB,
    nl,
    de,
    es,
};
const dateFormats = {
    en: "MMM d, yyyy",
    nl: "dd-MM-yyyy",
    es: "dd-MM-yyyy",
    de: "dd-MM-yyyy",
};

export default {
    name: "InboxCompanyDetailTable",
    components: {
        InboxTable,
    },
    props: {
        ID: {
            required: true,
        },
        compact: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            locale: (state) => state.LanguageStore.currentLanguage,
            companyReviewOpenPopups: (state) => state.CompanyReviewStore.Popups.open,
        }),
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
            getReviewByID: "CompanyReviewStore/getReviewByID",
        }),
        review() {
            return this.getReviewByID(this.ID);
        },
        loading() {
            return !this.activeAccount;
        },
        reviewIsOnHold() {
            return this.review.status === "on hold";
        },
        revisedText() {
            let revisedText;
            if (this.review.isRevised) {
                revisedText = this.$t("ja");
            } else if (!this.review.reinviteSent) {
                revisedText = this.$t("cp__inbox__revise_request_not_sent");
            } else {
                revisedText = this.$t("cp__inbox__revise_request_sent");
                if (this.review.reviseDate) {
                    revisedText += ` ${format(
                        new Date(this.review.reviseDate),
                        `${dateFormats[this.locale]} HH:mm`,
                        { locale: locales[this.locale] }
                    )}`;
                }
            }
            return revisedText;
        },
        productRowName() {
            if (this.loading) return "";
            return this.activeAccount.legacy.purchasedProductOverwrite
                ? this.activeAccount.legacy.purchasedProductOverwrite
                : this.$t("cp__step2__product");
        },
        tableData() {
            const { review } = this;
            return [
                {
                    name: this.$t("cp__step2__branch"),
                    value: review.branch.name ? review.branch.name : null,
                },
                {
                    name: this.$t("general__date_created"),
                    value: format(
                        new Date(review.date_created),
                        `${dateFormats[this.locale]} HH:mm`,
                        { locale: locales[this.locale] }
                    ),
                },
                {
                    name: this.$t("general__date_verified"),
                    value:
                        review.date_verified && !review.invite?.recipient_email
                            ? format(
                                  new Date(review.date_verified),
                                  `${dateFormats[this.locale]} HH:mm`,
                                  { locale: locales[this.locale] }
                              )
                            : null,
                },
                {
                    name: this.$t("publish_date"),
                    value:
                        this.reviewIsOnHold || !review.publish_date
                            ? null
                            : format(
                                  new Date(review.publish_date),
                                  `${dateFormats[this.locale]} HH:mm`,
                                  { locale: locales[this.locale] }
                              ),
                },
                {
                    name: this.$t("cp__inbox__review_id"),
                    value: review.id,
                },
                {
                    rowID: "filtercode",
                    name: this.$t("filtercode"),
                    value: review.filtercode,
                    editable: this.activeAccount.permissions.inbox.editProductFilterCode,
                },
                {
                    rowID: "product",
                    name: this.productRowName,
                    value: review.product,
                    editable: this.activeAccount.permissions.inbox.editProductFilterCode,
                },
                {
                    name: this.$t("cp__step2__gender"),
                    value: review.customer.gender,
                },
                {
                    name: this.$t("cp__step2__age"),
                    value: review.customer.age,
                },
                {
                    name: this.$t("cp__inbox__revised"),
                    value: this.revisedText,
                },
                {
                    name: this.$t("cp__inbox__order_number"),
                    value: review.order_number ? review.order_number : review.review_ref,
                },
                {
                    name: this.$t("cp__inbox__invited"),
                    value:
                        review.invite && review.invite.recipient_email
                            ? this.$t("cp__inbox__is__invited")
                            : this.$t("cp__inbox__is__not__invited"),
                },
            ].filter((row) => row.value || row.editable); // Remove empty values
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
$table_width: 332px;

.table {
    width: 100%;
    border: 1px solid $grey_athens;
    border-top: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-spacing: 0 4px;
    padding: 4px 0;
    td {
        padding: 4px 0 4px 16px;
    }
}
.company_review_detail__table_row_name {
    color: $grey_mid;
    font-size: 12px;
    word-wrap: break-word;
    hyphens: auto;
}
.company_review_detail__table_row_name--compact {
    width: 80px;
}
.company_review_detail__table_row_value {
    font-size: 12px;
    font-weight: 600;
    hyphens: auto;
    word-break: break-word;
}
</style>
