<template>
    <button
        @click="toggle"
        class="select_button"
        :class="{
            'select_button--selected': inputValue,
        }"
        type="button"
    >
        <slot></slot>
    </button>
</template>

<script>
import InputBase from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/mixins/InputBase";

export default {
    name: "SelectButton",
    mixins: [InputBase],
    methods: {
        toggle() {
            this.inputValue = !this.inputValue;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.select_button {
    @extend %body2_style;
    display: inline-flex;
    width: 100%;
    height: 54px;
    padding: 0 16px;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    outline: 0;
    border: 1px solid $grey_mischka;
    border-radius: 4px;
    transition: all 188ms;
    margin-bottom: 8px;
    &:hover {
        cursor: pointer;
    }
}
.select_button--selected {
    border: 1px solid $navy;
    background: $navy;
    color: $white;
}
</style>
