<template>
    <div class="product_question">
        <InboxNavigation :isMobile="isOverlay" />
        <InboxDetailLoader v-if="loading" class="product_question__loader" />
        <InboxDetailError v-else-if="hasError" :errors="review.error" />
        <div class="grid_holder" v-else>
            <InboxGrid
                class="product_question__inbox_grid"
                :class="{ 'product_question--is-single-column': isSingleColumn }"
                :isSingleColumn="isSingleColumn"
                :isOverlay="isOverlay"
                :ID="ID"
            >
                <template v-slot:header>
                    <header class="product_review__header">
                        <address class="product_review__author">
                            <h1 class="product_review__author_name">
                                {{ review.customer.fullname }}
                            </h1>
                            <h5 class="product_review__author_user_email">
                                {{ review.customer.email }}
                            </h5>
                        </address>
                        <hr class="product_review__author__devider" />
                        <section class="question_holder">
                            <AccountImage
                                :size="48"
                                :src="false"
                                :initial="review.customer.fullname[0]"
                            />
                            <p class="question_holder__question">
                                {{ review.text }}
                            </p>
                        </section>
                        <section class="question_holder" v-if="review.isAnswered">
                            <AccountImage
                                :size="48"
                                :src="activeAccount.logo"
                                :initial="accountName[0]"
                            />
                            <div>
                                <p
                                    class="question_holder__question question_holder__question--white"
                                >
                                    {{ review.answer }}
                                </p>
                                <div class="question_holder__actions">
                                    <span
                                        class="question_holder__action_btn"
                                        @click="focusReplyInput()"
                                    >
                                        {{ $t("cp__generic__edit") }}
                                    </span>
                                    <!-- <span class="question_holder__action_btn">{{ $t('cp__generic__delete') }}</span> -->
                                </div>
                            </div>
                        </section>
                    </header>
                </template>
                <template v-slot:aside>
                    <StatusIndicatorRow
                        :compact="true"
                        :isAnswered="review.isAnswered"
                        :createdDate="review.createdAt"
                        :answeredDate="review.answeredAt"
                        :maxDays="11"
                    />
                    <InboxQuestionDetailTable
                        :ID="ID"
                        :compact="!isSingleColumn"
                        class="question__sidebar_block"
                    />
                    <ProductBlock
                        v-if="!isSingleColumn"
                        :review="review"
                        class="question__sidebar_block"
                    />
                    <InboxQuestionActionBlock
                        v-if="!isSingleColumn"
                        class="product_question_detail__action_block question__sidebar_block"
                        :ID="ID"
                        @requestReplyInputFocus="focusReplyInput"
                    />
                </template>
                <template v-slot:main>
                    <!-- Gotten Review {{ review }} <br/> <hr/> -->
                </template>
            </InboxGrid>
            <InboxQuestionActionBlock
                v-if="!loading && !hasError && isSingleColumn"
                :ID="ID"
                @requestReplyInputFocus="focusReplyInput"
                class="product_question_detail__action_block product_question_detail__action_block--fixed"
                :class="{
                    'product_question_detail__action_block--open': actionBlockOpenMobile,
                    has_response: response,
                }"
            />
        </div>
        <!-- Only shown by default on smaller screens (singleColumn). On bigger screens user has to set this part active
        by clicking ActionBlock and set responseInputOpen to: true -->
        <InboxDetailActionBar
            v-show="isSingleColumn || responseInputOpen"
            class="action_bar"
            :isSingleColumn="isSingleColumn"
            :hideSideActions="isSingleColumn && responseInputOpen"
        >
            <template #default>
                <InboxResponseInput
                    v-model="response"
                    ref="replyInput"
                    :isSmall="isSingleColumn && isOverlay"
                    :isLoading="review.replySubmitting"
                    :ID="ID"
                    :onHold="false"
                    :hasResponse="hasResponse"
                    :hasUnsavedResponse="hasUnsavedResponse"
                    :isSingleColumn="isSingleColumn"
                    :hasSetPublicCheckbox="true"
                    :replyButtonText="$t('cp__inbox__answer_input__submit_button--publish')"
                    @submitRequest="submitReply"
                    @requestMoreSpace="setResponseInputOpen"
                    @requestLessSpace="setResponseInputClosed"
                    :maxLength="1000"
                />
            </template>
            <template #single_action>
                <InboxHamburgerButton
                    @click.native="toggleActionBlockOpenMobile"
                    :toggled="actionBlockOpenMobile"
                />
            </template>
        </InboxDetailActionBar>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import InboxGrid from "@/components/Inbox/Molecules/InboxGrid.vue";
import InboxDetailLoader from "@/components/Inbox/Molecules/InboxDetailLoader.vue";
import InboxDetailError from "@/components/Inbox/Molecules/InboxDetailError.vue";
import InboxNavigation from "@/components/Inbox/Molecules/InboxNavigation.vue";
import AccountImage from "@/components/Global/Atoms/AccountImage.vue";
import InboxQuestionDetailTable from "@/components/Inbox/Molecules/InboxQuestionDetailTable.vue";
import StatusIndicatorRow from "@/components/Inbox/Molecules/QuestionStatusIndicatorRow.vue";
import InboxQuestionActionBlock from "@/components/Inbox/Molecules/InboxQuestionActionBlock.vue";
import ProductBlock from "@/components/Inbox/Molecules/ProductBlock.vue";
import InboxResponseInput from "@/components/Inbox/Molecules/InboxResponseInput.vue";
import InboxDetailActionBar from "@/components/Inbox/Molecules/InboxDetailActionBar.vue";
import InboxHamburgerButton from "@/components/Inbox/Atoms/InboxHamburgerButton.vue";

export default {
    name: "InboxDetailQuestion",
    components: {
        InboxGrid,
        InboxDetailLoader,
        InboxNavigation,
        AccountImage,
        InboxQuestionDetailTable,
        StatusIndicatorRow,
        InboxQuestionActionBlock,
        ProductBlock,
        InboxResponseInput,
        InboxDetailActionBar,
        InboxHamburgerButton,
        InboxDetailError,
    },
    data() {
        return {
            response: "",
            responseInputOpen: false,
            actionBlockOpenMobile: false,
        };
    },
    props: {
        ID: {
            required: true,
        },
        isSingleColumn: {
            type: Boolean,
            default: false,
        },
        isOverlay: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            getReviewByID: "ProductQuestionStore/getReviewByID",
            activeAccount: "AccountStore/activeAccount",
            accountName: "AccountStore/accountName",
        }),
        review() {
            return this.getReviewByID(this.ID);
        },
        loading() {
            return this.review === undefined || this.review.loading;
        },
        hasError() {
            return this.review.hasError;
        },
        hasResponse() {
            return !!this.review.answer;
        },
        hasUnsavedResponse() {
            return this.hasResponse && this.review.answer !== this.response;
        },
    },
    watch: {
        ID() {
            this.IDChangeHandler();
        },
    },
    methods: {
        ...mapMutations({
            openPopup: "ProductQuestionStore/Popups/open",
        }),
        ...mapActions({
            getProductQuestionDetail: "ProductQuestionStore/get",
            setRead: "ProductQuestionStore/setRead",
            reply: "ProductQuestionStore/reply",
        }),
        submitReply({ reply, setPublic }) {
            this.reply({
                ID: this.ID,
                reply,
                setPublic,
                successMessage: {
                    title: this.$t("cp__notifications__inbox__answer__successful__title"),
                    message: this.$t("cp__notifications__inbox__answer__successful__message"),
                },
            });
        },
        setResponseInputOpen() {
            this.responseInputOpen = true;
            this.response = this.review.answer;
        },
        setResponseInputClosed() {
            this.responseInputOpen = false;
        },
        focusReplyInput() {
            this.$refs.replyInput.focus();
        },
        async IDChangeHandler() {
            this.getProductQuestionDetail({ ID: this.ID });
            await this.setRead({ ID: this.ID, isRead: true });
            this.response = "";
        },
        toggleActionBlockOpenMobile() {
            this.actionBlockOpenMobile = !this.actionBlockOpenMobile;
        },
    },
    mounted() {
        this.IDChangeHandler();
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.product_question {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.grid_holder {
    height: 100%;
    overflow: auto;
}
.action_bar {
    width: 100%;
    bottom: 0px;
    z-index: 3;
    flex-shrink: 0;
    flex-grow: 10;
    border-top: 1px solid $grey_athens;
}
.product_question__inbox_grid {
    width: 100%;
}
.product_question__loader {
    width: 100%;
    height: 100%;
}
.product_review__author {
    font-style: unset;
    margin-bottom: 36px;
}
.product_review__author_name {
    @extend %headline5_style;
    margin: 0px;
    line-height: 24px;
}
.product_review__author_user_email {
    @extend %body1_style;
    color: $grey_mid;
    line-height: 18px;
    margin: 12px 0 0 0;
}
.product_review__review_title {
    @extend %body1_style;
    position: relative;
    font-weight: 600;
    margin: 24px 0 8px;
}
.product_review__author__devider {
    width: 100%;
    border: 0px;
    margin: 0px;
    border-top: 1px solid;
    border-color: $grey_alabaster;
}
.question_holder {
    display: flex;
    margin-top: 36px;
}
.question_holder__question {
    @extend %body1_style;
    margin: 0 24px;
    background: $grey_alabaster;
    border: 1px solid $grey_athens;
    border-radius: 2px 18px 18px 18px;
    padding: 16px 20px;
}
.question_holder__question--white {
    @extend %body1_style;
    margin: 0 24px;
    background: $white;
    border: 1px solid $grey_athens;
    border-radius: 2px 18px 18px 18px;
    padding: 16px 20px;
}
.question_holder__actions {
    margin: 10px 46px;
    display: flex;
    gap: 14px;
    font-size: 12px;
}
.question_holder__action_btn {
    color: $grey_bombay;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        color: $black;
    }
}
.question__sidebar_block {
    margin: 0 0 24px;
}
.product_question_detail__action_block {
    position: sticky;
    top: -16px;
}
.product_question_detail__action_block--fixed {
    position: fixed;
    transform: translate3d(0, calc(100% + 16px), 0);
    bottom: 92px;
    width: calc(var(--inbox_detail_width) - 16px);
    top: auto;
    right: 8px;
    margin: 0px;
    z-index: 2;
    transition: transform 200ms;

    &.has_response {
        bottom: 102px;
    }
}
.product_question_detail__action_block--open {
    transform: translate3d(0, 0%, 0);
}
</style>
