<template>
    <div class="action_block" v-if="review">
        <button v-if="onHold" class="action_block__action" @click="openPublishConfirmPopup">
            <FontAwesomeIcon class="action_block__action_icon" :icon="['far', 'check-circle']" />
            <span>{{ $t("cp__inbox__publish_button_label") }}</span>
            <LoaderCircular
                class="action_bar__loader"
                v-if="review.approveSubmitting"
                :height="16"
            />
        </button>
        <button
            v-if="!hasResponse && showResponseInput"
            class="action_block__action"
            :class="{ 'is-primary': isPrimaryAction('reply') }"
            @click="requestReplyInputFocus"
        >
            <FontAwesomeIcon class="action_block__action_icon" :icon="['far', 'reply']" />
            <span>{{ replyButtonMessage }}</span>
        </button>
        <button v-if="review.isRead" class="action_block__action" @click="markUnreadHandler">
            <FontAwesomeIcon class="action_block__action_icon" :icon="['far', 'inbox']" />
            <span>{{ $t("cp__inbox_mark_unread_button__label") }}</span>
        </button>
    </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, faReply, faRedo, faInbox } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";

library.add(faCheckCircle, faReply, faRedo, faInbox);

export default {
    name: "InboxProductActionBlock",
    components: {
        FontAwesomeIcon,
        LoaderCircular,
    },
    props: {
        ID: {
            required: true,
        },
    },
    computed: {
        ...mapState({
            locale: (state) => state.LanguageStore.currentLanguage,
            companyReviewOpenPopups: (state) => state.ProductReviewStore.Popups.open,
        }),
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
            getReviewByID: "ProductReviewStore/getReviewByID",
        }),
        review() {
            return this.getReviewByID(this.ID);
        },
        onHold() {
            return this.review && this.review.status === "on hold";
        },
        hasResponse() {
            return this.review && !!this.review.shop_comment;
        },
        replyButtonMessage() {
            const { onHold } = this;
            let replyButtonMessage = this.$t("cp__inbox__reply_action__reply");
            if (onHold) replyButtonMessage = this.$t("cp__inbox__reply_action__approve");
            return replyButtonMessage;
        },
        showResponseInput() {
            return this.activeAccount.permissions.products.newProductReviewsEnabled;
        },
    },
    methods: {
        ...mapMutations({
            openPopup: "ProductReviewStore/Popups/open",
        }),
        ...mapActions({
            reply: "ProductReviewStore/reply",
            approve: "ProductReviewStore/approve",
            setRead: "ProductReviewStore/setRead",
        }),
        markUnreadHandler() {
            this.setRead({ ID: this.ID, isRead: false });
        },
        openRevisePopup() {
            this.openPopup({ ID: `revise_popup_${this.ID}` });
        },
        openPublishConfirmPopup() {
            this.openPopup({ ID: `publish_confirm_popup_${this.ID}` });
        },
        requestReplyInputFocus() {
            this.$emit("requestReplyInputFocus");
        },
        isPrimaryAction(action) {
            return (
                (action === "revise" && this.onHold && !this.review.reinviteSent) ||
                (action === "reply" &&
                    !this.hasResponse &&
                    (!this.onHold || this.review.reinviteSent))
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.action_block {
    width: 100%;
    border: 1px solid $grey_athens;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 12px 56px 0 rgba(30, 30, 30, 0.1);
}
.action_block__action {
    @extend %body2_style;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    margin: 0px;
    background: $white;
    border: 0px;
    width: 100%;
    border-bottom: 1px solid $grey_athens;

    &:last-of-type {
        border-bottom: 0px;
    }
    &.is-primary {
        border-right: 3px solid $blue;
    }
    &:hover {
        background: $grey_alabaster;
        cursor: pointer;
    }
    * {
        display: inline-block;
    }
}
.action_block__action_icon {
    margin-right: 16px;
}
</style>
