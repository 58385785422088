<template>
    <fieldset
        v-if="!hidden"
        class="filter_checkbox_row"
        :class="{
            'filter_checkbox_row--disabled': disabled,
        }"
    >
        <Checkbox v-model="positive" :label="labelTrue" class="filter_checkbox_row__checkbox" />
        <Checkbox
            v-model="negative"
            :label="labelFalse"
            class="filter_checkbox_row__checkbox filter_checkbox_row__checkbox--bottom"
        />
    </fieldset>
</template>

<script>
import InputBase from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/mixins/InputBase";
import Checkbox from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Checkbox.vue";

export default {
    mixins: [InputBase],
    name: "FilterCheckboxRow",
    components: {
        Checkbox,
    },
    props: {
        labelTrue: { type: String, required: true },
        labelFalse: { type: String, required: true },
        hidden: { type: Boolean, default: false },
    },
    data() {
        const { value } = this.$props;
        const positive = value;
        const negative = value === false;
        return {
            positive,
            negative,
        };
    },
    computed: {
        bothTrue() {
            return this.positive && this.negative;
        },
        computedValue() {
            let inputValue = null;
            if (this.positive) inputValue = true;
            if (this.negative) inputValue = false;
            return inputValue;
        },
    },
    watch: {
        positive() {
            this.forceValid({ latest: "positive" });
        },
        negative() {
            this.forceValid({ latest: "negative" });
        },
        computedValue() {
            this.inputValue = this.computedValue;
        },
    },
    methods: {
        forceValid({ latest }) {
            if (!this.bothTrue) return;
            const overwriteValue = latest === "positive" ? "negative" : "positive";
            this[overwriteValue] = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.filter_checkbox_row {
    width: 100%;
    max-width: 50%;
    padding: 8px;
    border-radius: 4px;
    vertical-align: top;
}
.filter_checkbox_row--disabled {
    opacity: 0.3;
    pointer-events: none;
}
.filter_checkbox_row__checkbox {
    width: 100%;
    max-width: 200px;
    display: block;
}
.filter_checkbox_row__checkbox--bottom {
    margin-top: 8px;
}
</style>
