<template>
    <div
        class="inbox_detail"
        :class="{
            'inbox_detail--has-review': reviewID,
        }"
    >
        <span v-if="popupsOpen === 0" class="inbox-shortcuts">
            <EventListener
                v-if="previousAndNextReview.previous && previousAndNextReview.previous.ID"
                @keydown.prevent.up="goToPreviousReview"
            />
            <EventListener
                v-if="previousAndNextReview.previous && previousAndNextReview.previous.ID"
                @keydown.prevent.left="goToPreviousReview"
            />
            <EventListener
                v-if="previousAndNextReview.next && previousAndNextReview.next.ID"
                @keydown.prevent.down="goToNextReview"
            />
            <EventListener
                v-if="previousAndNextReview.next && previousAndNextReview.next.ID"
                @keydown.prevent.right="goToNextReview"
            />
        </span>
        <div v-if="numerOfSelectedItems > 0" class="no_results_holder">
            <div class="no_results">
                <img
                    class="image"
                    src="../../../assets/images/inbox-selection.svg"
                    alt="mark as read"
                />
                <div>
                    <div class="headline6">
                        {{ numerOfSelectedItems }} {{ $t("cp__inbox__items_selected") }}
                    </div>
                    <div>
                        <font-awesome-icon class="mark_as_read_star" :icon="['fas', 'star']" />
                        <TextButton
                            :label="$t('cp__inbox__items_mark_as_read')"
                            @click="setReviewsReadStatus(true)"
                        />
                    </div>
                    <div>
                        <font-awesome-icon class="mark_as_unread_star" :icon="['fas', 'star']" />
                        <TextButton
                            :label="$t('cp__inbox__items_mark_as_unread')"
                            @click="setReviewsReadStatus(false)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="!internalID" class="no_results_holder">
            <div class="no_results">
                <h6 class="no_results__title headline6">
                    {{ $t("cp__inbox__no_messages_selected") }}
                </h6>
            </div>
        </div>
        <component
            v-else
            class="inbox_detail_data"
            :is="reviewComponent"
            :isSingleColumn="isSingleColumn"
            :isOverlay="isOverlay"
            :ID="internalID"
        />
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { vueWindowSizeMixin } from "vue-window-size";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import InboxDetailCompany from "@/components/Inbox/Organisms/InboxDetailCompany.vue";
import InboxDetailProduct from "@/components/Inbox/Organisms/InboxDetailProduct.vue";
import InboxDetailQuestion from "@/components/Inbox/Organisms/InboxDetailQuestion.vue";
import EventListener from "@/components/Global/Atoms/EventListener.vue";
import TextButton from "@/components/Global/Atoms/TextButton.vue";
import setReviewsReadStatus from "@/graphql/inbox/setReviewsReadStatus.gql";
import setReviewsReadStatusAll from "@/graphql/inbox/setReviewsReadStatusAll.gql";

library.add(faChevronDown, faChevronUp, faChevronLeft, faChevronRight, faStar);

export default {
    name: "InboxDetail",
    mixins: [vueWindowSizeMixin],
    components: {
        InboxDetailCompany,
        InboxDetailProduct,
        InboxDetailQuestion,
        EventListener,
        FontAwesomeIcon,
        TextButton,
    },
    props: {
        width: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            internalID: null,
            internalType: null,
        };
    },
    computed: {
        ...mapState({
            numerOfSelectedItems: (state) => state.InboxStore.numerOfSelectedItems,
            areAllSelected: (state) => state.InboxStore.areAllSelected,
            locale: (state) => state.LanguageStore.currentLanguage,
            sorting: (state) => state.InboxStore.sorting,
        }),
        ...mapGetters({
            previousAndNextReview: "InboxStore/getPreviousAndNextReview",
            popupsOpen: "CompanyReviewStore/Popups/openCount",
            getIdsOfSelectedItems: "InboxStore/getIdsOfSelectedItems",
        }),
        reviewType() {
            return this.$route.params.type;
        },
        reviewID() {
            return this.$route.params.ID;
        },
        reviewComponent() {
            if (this.internalType === "product") return InboxDetailProduct;
            if (this.internalType === "question") return InboxDetailQuestion;
            return InboxDetailCompany;
        },
        isSingleColumn() {
            return this.width < 768;
        },
        isOverlay() {
            return this.windowWidth < 1044;
        },
        buttonIcons() {
            const previous = this.windowWidth < 1000 ? "chevron-up" : "chevron-left";
            const next = this.windowWidth < 1000 ? "chevron-down" : "chevron-right";
            return {
                previous,
                next,
            };
        },
        buttonTarget() {
            return this.windowWidth < 1000 ? "mobile" : "desktop";
        },
    },
    methods: {
        ...mapActions({
            reloadAllInboxChunks: "InboxStore/reloadAllInboxChunks",
        }),
        // We do it this way, because when the interface is in overlay mode, we animate it and -
        // - the review should only be removed from the interface once the overlay has animated out
        setInternalID() {
            if (!this.isOverlay || (this.isOverlay && !this.internalID)) {
                this.internalID = this.reviewID;
                this.internalType = this.reviewType;
                return;
            }
            setTimeout(() => {
                this.internalID = this.reviewID;
                this.internalType = this.reviewType;
            }, 400);
        },
        goToReview({ params }) {
            if (!this.reviewID) return;
            this.$router.push({
                name: this.$route.name,
                params: { ...params },
                query: this.$route.query,
                meta: {
                    programmaticNavigation: true,
                },
            });
        },
        goToPreviousReview() {
            const { previous } = this.previousAndNextReview;
            this.goToReview({ params: previous });
        },
        goToNextReview() {
            const { next } = this.previousAndNextReview;
            this.goToReview({ params: next });
        },
        async setReviewsReadStatus(status) {
            try {
                if (this.areAllSelected) {
                    const filters = { ...this.sorting };
                    delete filters.sortBy;
                    delete filters.sortOrder;
                    await this.$apollo.mutate({
                        mutation: setReviewsReadStatusAll,
                        variables: {
                            input: { readStatus: status, language: this.locale, ...filters },
                        },
                        context: {
                            headers: {
                                "X-Shop-Uuid": this.$store.state.AccountStore.shopData.externalUuid,
                            },
                        },
                    });
                } else {
                    await this.$apollo.mutate({
                        mutation: setReviewsReadStatus,
                        variables: {
                            input: {
                                readStatus: status,
                                language: this.locale,
                                merchantReviews: this.getIdsOfSelectedItems("merchant"),
                                productReviews: this.getIdsOfSelectedItems("product"),
                                productQuestions: this.getIdsOfSelectedItems("question"),
                            },
                        },
                        context: {
                            headers: {
                                "X-Shop-Uuid": this.$store.state.AccountStore.shopData.externalUuid,
                            },
                        },
                    });
                }
                await this.$store.dispatch("pushNotification", {
                    type: "success",
                    title: this.$t("cp__generic__success"),
                    message: this.$t("cp__inbox__mark_review_success_message"),
                });
                this.reloadAllInboxChunks();
                await this.$store.dispatch("NotificationStore/getNotificationCount");
            } catch (error) {
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__generic__error_title"),
                    message: this.$t("cp__generic__error"),
                });
            }
        },
    },
    watch: {
        reviewID() {
            this.setInternalID();
        },
    },
    mounted() {
        this.setInternalID();
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox_detail {
    background: $white;
    min-width: 400px;
    @media (max-width: 1043px) {
        transition: all 400ms ease;
        position: absolute;
        width: calc(100% - #{$nav_width});
        transform: translateX(100%);
        min-width: auto;
    }
}
.inbox-shortcuts {
    position: absolute;
}
.inbox_detail--has-review {
    @media (max-width: 1043px) {
        transform: translateX(0%);
    }
}
.inbox_detail_data {
    position: relative;
}
.no_results_holder {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
        margin: 15px 0 0 30px;
    }
}
.no_results__title {
    text-align: center;
}
.menubar__inbox_navigation_button {
    width: 48px;
    height: 48px;
    margin-right: 8px;
    background-color: $grey_bg;
    border: 0px;
    outline: 0;
}
.menubar__inbox_navigation_button_icon {
    font-size: 16px;
}
.mark_as_read_star {
    color: $star_gold;
    opacity: 0.5;
    margin-right: 10px;
}
.mark_as_unread_star {
    color: $star_gold;
    margin-right: 10px;
}
</style>
