<template>
    <button :disabled="disabled" type="button" class="text-button" @click="$emit('click', $event)">
        <span>{{ label }}</span>
    </button>
</template>
<script>
export default {
    name: "TextButton",
    props: {
        label: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.text-button {
    @extend %button_typography_style;
    border: none;
    cursor: pointer;
    padding: 0;
    color: $blue;
    background: $white;

    &:hover {
        color: $blue_sapphire;
    }

    &:disabled {
        color: $grey_french;
    }
}
</style>
