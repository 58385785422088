<template>
    <tr v-if="!editing" class="table__row">
        <td class="table_row__name" :class="{ 'table_row__name--compact': compact }">
            {{ name }}
        </td>
        <td @click="startEditing" class="table_row__value table_row__value--editable">
            <div class="table_row__value_content">
                <div>{{ displayValue }}</div>
                <div class="table_row__value_edit_button">
                    <font-awesome-icon class="table_row__value_icon" :icon="['far', 'pencil']" />
                </div>
            </div>
        </td>
    </tr>
    <tr v-else class="table__row--editing">
        <EventListener @keyup.prevent.esc="stopEditing" />
        <td class="table_row__name">
            {{ name }}
        </td>
        <td class="table_row--editing">
            <div class="table_row__value_content">
                <textarea-autosize
                    class="table__input"
                    v-model="inputValue"
                    :autosize="inputValue && inputValue.length > 0"
                ></textarea-autosize>
                <div @click="requestSave" class="table_row__save_button">
                    <font-awesome-icon class="table_row__value_icon" :icon="['fas', 'check']" />
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
import InputBase from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/mixins/InputBase";
import { mapActions } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import EventListener from "@/components/Global/Atoms/EventListener.vue";

library.add(faPencil, faCheck);

export default {
    name: "EditableRow",
    mixins: [InputBase],
    components: {
        EventListener,
        FontAwesomeIcon,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        reviewID: {
            required: true,
        },
        rowID: {
            required: true,
        },
        compact: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            editing: false,
        };
    },
    computed: {
        displayValue() {
            return this.value ? this.value : "-";
        },
    },
    methods: {
        ...mapActions({
            updateReviewData: "CompanyReviewStore/updateReviewData",
        }),
        startEditing() {
            this.editing = true;
        },
        stopEditing() {
            this.editing = false;
        },
        requestSave() {
            const saveData = {
                ID: this.reviewID,
            };
            saveData[this.rowID] = this.inputValue;
            this.updateReviewData(saveData);
            // this.stopEditing();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

td {
    padding: 4px 0 4px 16px;
}
.table_row__name {
    color: $grey_mid;
    font-size: 12px;
}
.table_row__name--compact {
    width: 80px;
}
.table_row--editing,
.table_row__value {
    font-size: 12px;
    font-weight: 600;
    hyphens: auto;
    word-break: break-word;
}
.table_row__value_content {
    display: flex;
    align-items: center;
}
.table__input {
    @extend %body2_style;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    hyphens: auto;
    word-break: break-word;
    display: flex;
    align-items: center;
}
.table_row__value_edit_button {
    display: inline-block;
    margin-left: auto;
    padding-right: 16px;
}
.table_row__value_icon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    padding: 4px;
    display: inline-block;
    background: $grey_alabaster;
    color: $grey_french;
}
.table_row__value--editable {
    &:hover {
        cursor: pointer;
        .table_row__value_icon {
            background: $grey_athens;
            color: $grey_shark;
        }
    }
}
.table_row__save_button {
    color: $blue;
    background: none;
    border: 0px;
    padding: 0 16px 0 8px;
    .table_row__value_icon {
        width: auto;
        color: $blue;
    }
    &:hover {
        cursor: pointer;
    }
}
</style>
