<template>
    <div class="inbox_navigation" :class="{ inbox_navigation_border: isMobile }">
        <div>
            <BackButton
                class="inbox_button"
                :label="$t('cp__inbox__navigation_previous')"
                :to="previous"
                v-if="previousAndNextReview.previous && previousAndNextReview.previous.ID"
            />
        </div>
        <div>
            <NextButton
                class="inbox_button"
                :label="$t('cp__inbox__navigation_next')"
                :to="next"
                v-if="previousAndNextReview.next && previousAndNextReview.next.ID"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import BackButton from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/BackButton.vue";
import NextButton from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/NextButton.vue";

export default {
    name: "InboxNavigation",
    components: {
        BackButton,
        NextButton,
    },
    props: {
        isMobile: {
            type: Boolean,
        },
    },
    computed: {
        ...mapGetters({
            previousAndNextReview: "InboxStore/getPreviousAndNextReview",
        }),
        next() {
            const { next } = this.previousAndNextReview;
            return {
                name: this.$route.name,
                params: { ...next },
                query: this.$route.query,
                meta: {
                    programmaticNavigation: true,
                },
            };
        },
        previous() {
            const { previous } = this.previousAndNextReview;
            return {
                name: this.$route.name,
                params: { ...previous },
                query: this.$route.query,
                meta: {
                    programmaticNavigation: true,
                },
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
.inbox_navigation_border {
    border-bottom: 1px solid $grey_alabaster;
}
.inbox_navigation {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}
.inbox_button {
    margin: 0px 24px;
    height: 56px;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
}
</style>
