<template>
    <InboxTable v-if="!loading" :rows="tableData" :compact="compact" :ID="ID" />
</template>

<script>
import { format } from "date-fns";
import { enGB, nl, de, es } from "date-fns/locale";
import { mapState, mapGetters } from "vuex";
import InboxTable from "@/components/Inbox/Molecules/InboxTable.vue";

const locales = {
    en: enGB,
    nl,
    de,
    es,
};
const dateFormats = {
    en: "MMM d, yyyy",
    nl: "dd-MM-yyyy",
    es: "dd-MM-yyyy",
    de: "dd-MM-yyyy",
};

export default {
    name: "InboxQuestionDetailTable",
    components: {
        InboxTable,
    },
    props: {
        ID: {
            required: true,
        },
        compact: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            locale: (state) => state.LanguageStore.currentLanguage,
        }),
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
            getReviewByID: "ProductQuestionStore/getReviewByID",
        }),
        review() {
            return this.getReviewByID(this.ID);
        },
        loading() {
            return !this.activeAccount || !this.review;
        },
        tableData() {
            const { review } = this;
            const { $t } = this;
            return [
                {
                    name: $t("general__date_created"),
                    value: format(new Date(review.createdAt), `${dateFormats[this.locale]} HH:mm`, {
                        locale: locales[this.locale],
                    }),
                },
                {
                    name: $t("publish_date"),
                    value: review.isPublished
                        ? format(
                              new Date(review.publishedAt),
                              `${dateFormats[this.locale]} HH:mm`,
                              { locale: locales[this.locale] }
                          )
                        : $t("cp__inbox__questions__table__not_published"),
                },
                {
                    name: $t("cp__inbox__questions__table__answered_row_title"),
                    value: review.isAnswered
                        ? format(new Date(review.answeredAt), `${dateFormats[this.locale]} HH:mm`, {
                              locale: locales[this.locale],
                          })
                        : $t("cp__inbox__questions__table__not_answered"),
                },
                {
                    name: $t("cp__inbox__question_id"),
                    value: review.id,
                },
                {
                    name: $t("cp__inbox__questions__table__public"),
                    value: review.isPublished ? $t("ja") : $t("nee"),
                },
                {
                    name: $t("cp__inbox__questions__table__answered"),
                    value: review.isAnswered ? $t("ja") : $t("nee"),
                },
            ].filter((row) => row.value); // Remove empty values
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
$table_width: 332px;

.table {
    width: 100%;
    border: 1px solid $grey_athens;
    border-top: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-spacing: 0 4px;
    padding: 4px 0;
    td {
        padding: 4px 0 4px 16px;
    }
}
.company_review_detail__table_row_name {
    color: $grey_mid;
    font-size: 12px;
    word-wrap: break-word;
    hyphens: auto;
}
.company_review_detail__table_row_name--compact {
    width: 80px;
}
.company_review_detail__table_row_value {
    font-size: 12px;
    font-weight: 600;
    hyphens: auto;
    word-break: break-word;
}
</style>
