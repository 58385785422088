<template>
    <div class="action_block" v-if="question">
        <button
            v-if="!question.isAnswered"
            @click="requestReplyInputFocus"
            class="action_block__action"
            :class="{ 'is-primary': isPrimaryAction('reply') }"
        >
            <FontAwesomeIcon class="action_block__action_icon" :icon="['far', 'reply']" />
            <span>{{ replyButtonMessage }}</span>
        </button>
        <button v-if="question.isAnswered" @click="setPublicHandler" class="action_block__action">
            <FontAwesomeIcon
                class="action_block__action_icon"
                :icon="['far', question.isPublished ? 'eye-slash' : 'eye']"
            />
            <span>{{ publishButtonLabel }}</span>
        </button>
        <button v-if="question.isRead" @click="markUnreadHandler" class="action_block__action">
            <FontAwesomeIcon class="action_block__action_icon" :icon="['far', 'inbox']" />
            <span>{{ $t("cp__inbox_mark_unread_button__label") }}</span>
        </button>
    </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faReply, faRedo, faInbox, faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapState, mapGetters, mapActions } from "vuex";

library.add(faReply, faRedo, faInbox, faEye, faEyeSlash);

export default {
    name: "InboxQuestionActionBlock",
    components: {
        FontAwesomeIcon,
    },
    props: {
        ID: {
            required: true,
        },
    },
    computed: {
        ...mapState({
            locale: (state) => state.LanguageStore.currentLanguage,
        }),
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
            getReviewByID: "ProductQuestionStore/getReviewByID",
        }),
        question() {
            return this.getReviewByID(this.ID);
        },
        replyButtonMessage() {
            const { isAnswered } = this.question;
            let replyButtonMessage = this.$t("cp__inbox__question_action_block__reply");
            if (isAnswered)
                replyButtonMessage = this.$t("cp__inbox__question_action_block__edit_reply");
            return replyButtonMessage;
        },
        publishButtonLabel() {
            const { isPublished } = this.question;
            let replyButtonMessage = this.$t("cp__inbox__question_action_block__publish");
            if (isPublished)
                replyButtonMessage = this.$t("cp__inbox__question_action_block__unpublish");
            return replyButtonMessage;
        },
    },
    methods: {
        ...mapActions({
            setPublic: "ProductQuestionStore/setPublic",
            setRead: "ProductQuestionStore/setRead",
        }),
        setPublicHandler() {
            this.setPublic({ ID: this.ID, setPublic: !this.question.isPublished });
        },
        markUnreadHandler() {
            this.setRead({ ID: this.ID, isRead: false });
        },
        requestReplyInputFocus() {
            this.$emit("requestReplyInputFocus");
        },
        isPrimaryAction(action) {
            return (
                (action === "revise" && this.onHold && !this.question.reinviteSent) ||
                (action === "reply" &&
                    !this.hasResponse &&
                    (!this.onHold || this.question.reinviteSent))
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.action_block {
    width: 100%;
    border: 1px solid $grey_athens;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 12px 56px 0 rgba(30, 30, 30, 0.1);
}
.action_block__action {
    @extend %body2_style;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    margin: 0px;
    background: $white;
    border: 0px;
    width: 100%;
    border-bottom: 1px solid $grey_athens;

    &:last-of-type {
        border-bottom: 0px;
    }
    &.is-primary {
        border-right: 3px solid $blue;
    }
    &:hover {
        background: $grey_alabaster;
        cursor: pointer;
    }
    * {
        display: inline-block;
    }
}
.action_block__action_icon {
    margin-right: 16px;
}
</style>
