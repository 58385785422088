<template>
    <span>
        <label v-if="customLabel" class="detail_rating_label">{{ customLabel }}</label>
        <div class="detail_rating">
            <Stars :rating="rating" :size="36" />
            <span class="detail__written_rating">
                <span class="detail__rating_score">{{ rating }}/10</span>
            </span>
        </div>
    </span>
</template>

<script>
import Stars from "@/components/Inbox/Atoms/Stars.vue";

export default {
    name: "FinalScore",
    components: {
        Stars,
    },
    props: {
        rating: {
            required: true,
        },
        customLabel: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.detail_rating_label {
    @extend %body2_style;
    font-size: 0.9375em; // 15px
    display: inline-block;
    font-weight: 600;
    color: $black;
    padding-bottom: 4px;
}
.detail_rating {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    white-space: nowrap;
}
.detail__rating_score {
    @extend %body1_style;
    font-size: 20px;
    font-weight: 700;
    margin: 0 12px;
}
</style>
