<template>
    <div
        class="inbox_detail_action_bar"
        :class="{
            'inbox_detail_action_bar--single_column': isSingleColumn,
            'inbox_detail_action_bar--hide_side_actions': hideSideActions,
        }"
    >
        <div class="inbox_detail_action_bar__middle_actions">
            <slot></slot>
        </div>
        <!-- Actions buttons go in here -->
        <div
            v-if="!hideSideActions"
            class="inbox_detail_action_bar__side_buttons"
            :class="{ 'inbox_detail_action_bar__side_buttons--collapsed': responseInputCollapsed }"
        >
            <slot name="single_action" v-if="isSingleColumn"></slot>
            <slot name="start" v-if="!isSingleColumn"></slot>
            <slot name="end" v-if="!isSingleColumn"></slot>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "InboxDetailActionBar",
    props: {
        isSingleColumn: {
            type: Boolean,
            default: false,
        },
        hideSideActions: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            responseInputCollapsed: (state) => state.InboxStore.responseInputCollapsed,
        }),
    },
};
</script>

<style lang="scss" scoped>
@import "~include-media";
@import "@/style_variables/style_variables.scss";
$table_width: 250px;

.inbox_detail_action_bar {
    width: 100%;
    background-color: $white;
    padding: 24px 40px 24px 30px;
    display: grid;
    row-gap: 24px;
    column-gap: 36px;
    grid-template-columns: auto $table_width;
    grid-template-rows: auto;
    grid-template-areas: "main aside";
}
.inbox_detail_action_bar--single_column {
    grid-gap: 8px;
    grid-template-columns: 1fr min-content;
    padding: 12px 16px 12px 16px;
}
.inbox_detail_action_bar__middle_actions {
    display: flex;
    justify-content: center;
    grid-area: main;
    max-width: 700px;
}
.inbox_detail_action_bar__side_buttons {
    display: flex;
    justify-content: flex-end;
    grid-area: aside;
    align-items: flex-end;

    &.inbox_detail_action_bar__side_buttons--collapsed {
        align-items: start;
    }
    @include media($isPhone...) {
        align-items: start;
    }
}
</style>
