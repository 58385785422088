<template>
    <div
        class="status_indicator_row"
        :class="{
            'status_indicator_row--icon_only': iconOnly,
            'status_indicator_row--compact': !iconOnly && compact,
        }"
        :data-warning-level="warningLevel"
    >
        <div v-if="!iconOnly && compact">
            <div class="status_indicator_row__status_name">
                <slot name="status_label_slot"></slot>
            </div>
            <div class="status_indicator_row__time_left">
                <slot name="time_left_slot"></slot>
            </div>
        </div>
        <div class="status_indicator_row__status">
            <slot name="icon_slot"></slot>
        </div>
        <div v-if="!iconOnly && !compact" class="status_indicator_row__time_left">
            <slot name="time_left_slot"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "StatusIndicatorRowLayout",
    props: {
        warningLevel: {
            type: Number,
            default: 0,
        },
        compact: {
            type: Boolean,
            default: false,
        },
        iconOnly: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
$table_width: 332px;

.status_indicator_row {
    @extend %body2_style;
    max-width: $table_width;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 180ms;
}
.status_indicator_row--icon_only {
    width: 44px;
    height: 44px;
    padding: 0px;
    border-radius: 4px;
    justify-content: center;
    .status_indicator_row__status_icon {
        font-size: 18px;
    }
}
.status_indicator_row--compact {
    height: 56px;
}
.status_indicator_row[data-warning-level="1"] {
    background: $red;
    color: $white;
}
.status_indicator_row[data-warning-level="0"] {
    background: $green;
    color: $white;
    .status_indicator_row__status {
        color: $white;
    }
}
.status_indicator_row__status {
    flex-shrink: 0;
}
.status_indicator_row__status_name {
    font-weight: bold;
    &.status_indicator_row__status_name--uncondensed {
        margin-left: 8px;
    }
}
.status_indicator_row__time_left {
    flex-shrink: 0;
    font-weight: 300;
}
</style>
