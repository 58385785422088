<template>
    <div class="inbox_detail_loader">
        <LoaderCircular />
    </div>
</template>

<script>
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";

export default {
    name: "InboxDetailName",
    components: { LoaderCircular },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox_detail_loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
