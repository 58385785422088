<template>
    <div class="product_block">
        <div class="product_block__product_image_container">
            <img
                alt="Image"
                v-if="!error"
                class="product_block__image"
                :src="review.image_url"
                @error="onImageLoadError"
            />
            <div v-else class="product_block__fallback">
                <font-awesome-icon :icon="['fal', 'shopping-bag']" size="3x" />
            </div>
        </div>
        <span class="product_block__name">{{ review.product_name }}</span>
    </div>
</template>

<script>
import { faShoppingBag } from "@fortawesome/pro-light-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faShoppingBag);

export default {
    name: "ProductBlock",
    components: {
        FontAwesomeIcon,
    },
    data() {
        return {
            error: false,
        };
    },
    props: {
        review: {
            type: Object,
            required: true,
        },
    },
    methods: {
        onImageLoadError() {
            this.error = true;
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/style_variables/style_variables.scss";
$table_width: 332px;

.product_block {
    width: 100%;
    max-width: $table_width;
    border: 1px solid $grey_mischka;
    border-radius: 8px;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.product_block__product_image_container {
    width: 96px;
    height: 96px;
    background: $white;
    margin-bottom: 12px;
}
.product_block__image,
.product_block__fallback {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid $grey_mischka;
}
.product_block__image {
    object-fit: cover;
}
.product_block__fallback {
    color: $grey_mischka;
    display: flex;
    justify-content: center;
    align-items: center;
}
.product_block__name {
    @extend %caption_style;
    width: 85%;
    text-align: center;
    font-weight: 600;
}
</style>
