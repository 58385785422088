<template>
    <article
        class="inbox_grid"
        :class="{
            'inbox_grid--single_column': isSingleColumn,
            'inbox_grid--overlay': isOverlay,
            'inbox_grid--is_rerendering_grid': isRerenderingGridInSafari,
        }"
    >
        <div class="inbox_grid__review">
            <header class="inbox_grid__review__header"><slot name="header"></slot></header>
            <main class="inbox_grid__review__main"><slot name="main"></slot></main>
        </div>
        <aside class="inbox_grid__aside"><slot name="aside"></slot></aside>
        <slot></slot>
    </article>
</template>

<script>
export default {
    name: "InboxGrid",
    props: {
        isSingleColumn: {
            type: Boolean,
            default: false,
        },
        isOverlay: {
            type: Boolean,
            default: false,
        },
        ID: {
            required: true,
        },
    },
    data() {
        return {
            isRerenderingGridInSafari: false, // flag to toggle display: grid
        };
    },
    watch: {
        ID(newValue) {
            if (!newValue) {
                this.rerenderGridInSafari();
            }
        },
    },
    methods: {
        rerenderGridInSafari() {
            // workaround for rendering bug in Safari
            if (this.$store.state.browserInfo.name !== "safari") return;

            this.isRerenderingGridInSafari = true;
            window.requestAnimationFrame(() => {
                this.isRerenderingGridInSafari = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

$table_width: 250px;

.inbox_grid {
    @extend %body2_style;
    width: 100%;
    font-size: 16px;
    padding: 40px 40px 40px 30px;
    display: grid !important;
    row-gap: 24px;
    column-gap: 36px;
    grid-template-columns: auto $table_width;
    grid-template-rows: auto;
    grid-template-areas:
        "review aside"
        "popup popup";
    overflow-y: auto;
}
.inbox_grid--single_column {
    grid-gap: 36px;
    height: 100%;
    grid-template-columns: auto;
    grid-template-areas:
        "review"
        "aside";
}
.inbox_grid--is_rerendering_grid {
    display: inline-block;
    opacity: 0;
    transform: translate(-1px, 0px);
}
.inbox_grid--overlay {
    padding: 16px 24px;
}
.inbox_grid__review {
    grid-area: review;
}
.inbox_grid__review__main {
    margin-top: 36px;
}
</style>
