<template>
    <StatusIndicatorRowLayout :compact="compact" :iconOnly="iconOnly" :warningLevel="warningLevel">
        <template v-slot:status_label_slot>{{ statusLabel }}</template>
        <template v-slot:icon_slot>
            <font-awesome-icon
                class="status_indicator_row__status_icon"
                :icon="['fas', statusIcon]"
            />
        </template>
        <template v-slot:time_left_slot>
            <span v-if="onHold">{{ $t("cp__general__timeleft", { time: dateLabel }) }}</span>
            <span v-else>{{ dateLabel }}</span>
        </template>
    </StatusIndicatorRowLayout>
</template>

<script>
import { mapState } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faClock, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { add, formatDistance } from "date-fns";
import { enGB, nl, de, es } from "date-fns/locale";
import StatusIndicatorRowLayout from "@/components/Inbox/Atoms/StatusIndicatorRowLayout.vue";

const locales = {
    en: enGB,
    nl,
    de,
    es,
};

library.add(faClock, faCheckCircle);

export default {
    name: "ReviewStatusIndicatorRow",
    components: {
        FontAwesomeIcon,
        StatusIndicatorRowLayout,
    },
    props: {
        onHold: {
            type: Boolean,
            required: true,
        },
        published: {
            type: Boolean,
            default: false,
        },
        daysLeft: {
            default: 0,
            required: false,
        },
        publicationDate: {
            required: false,
        },
        maxDays: {
            type: Number,
            required: true,
        },
        compact: {
            type: Boolean,
            default: false,
        },
        iconOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            componentLabels: ["cp__general__timeleft"],
        };
    },
    computed: {
        ...mapState({
            locale: (state) => state.LanguageStore.currentLanguage,
        }),
        statusIcon() {
            let statusIcon = "clock";
            if (!this.onHold) statusIcon = "check-circle";
            return statusIcon;
        },
        statusLabel() {
            let statusLabel = this.$t("On hold");
            if (!this.onHold) statusLabel = this.$t("Goedgekeurd");
            if (this.published)
                statusLabel = this.$t("cp__notifications__inbox__approve__successful__title");
            return statusLabel;
        },
        warningLevel() {
            if (!this.onHold) return 0;
            return 1;
        },
        dateLabel() {
            let dateLabel = formatDistance(add(new Date(), { days: this.daysLeft }), new Date(), {
                locale: locales[this.locale],
            });
            if (!this.onHold) {
                dateLabel = formatDistance(new Date(this.publicationDate), new Date(), {
                    addSuffix: true,
                    locale: locales[this.locale],
                });
            }
            return dateLabel;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
$table_width: 332px;

.status_indicator_row {
    @extend %body2_style;
    max-width: $table_width;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.status_indicator_row--icon_only {
    width: 44px;
    height: 44px;
    padding: 0px;
    border-radius: 4px;
    justify-content: center;
    .status_indicator_row__status_icon {
        font-size: 18px;
    }
}
.status_indicator_row--compact {
    height: 56px;
}
.status_indicator_row[data-warning-level="1"] {
    background: $red;
    color: $white;
}
.status_indicator_row[data-warning-level="0"] {
    background: $green;
    color: $white;
    .status_indicator_row__status {
        color: $white;
    }
}
.status_indicator_row__status {
    flex-shrink: 0;
}
.status_indicator_row__status_name {
    font-weight: bold;
    &.status_indicator_row__status_name--uncondensed {
        margin-left: 8px;
    }
}
.status_indicator_row__time_left {
    flex-shrink: 0;
    font-weight: 300;
}
</style>
