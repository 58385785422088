<template>
    <div class="inbox_items">
        <InboxFilters />
        <InboxItemsHeader />
        <div class="selected_checkbox_containter">
            <TextButton
                v-if="!selectState"
                :label="$t('cp__inbox__select_mode')"
                @click="setSelectState"
            />
            <SelectedCheckbox
                v-if="selectState"
                :value="areAllSelected"
                id="main"
                class="selected_checkbox"
                @change="selectOrUnselectAllReviews({ selection: $event })"
            />
            <TextButton
                v-if="selectState"
                :label="areAllSelected ? $t('DESELECT_ALL') : $t('SELECT_ALL')"
                @click="selectOrUnselectAllReviews({ selection: !areAllSelected })"
            />
            <TextButton
                v-if="selectState && isOverlay"
                :disabled="numerOfSelectedItems === 0"
                :label="
                    displayMarkAsUnread
                        ? $t('cp__inbox__items_mark_as_unread')
                        : $t('cp__inbox__items_mark_as_read')
                "
                @click="setReviewsReadStatus(!displayMarkAsUnread)"
            />
            <TextButton
                v-if="selectState && !isOverlay"
                :label="$t('CANCEL')"
                @click="
                    selectOrUnselectAllReviews({ selection: false });
                    setSelectState();
                "
            />
        </div>
        <div v-if="!initialLoadDone" class="inbox_items__loader">
            <LoaderCircular />
        </div>
        <div v-else-if="paging.total === 0" v-show="initialLoadDone" class="no_results_holder">
            <div v-if="filtersActive.length === 0" class="no_results">
                <h6 class="no_results__title headline6">
                    {{ $t("cp__inbox__no_results__title") }}
                    <br />
                    {{ $t("cp__inbox__no_results__subtitle") }}
                </h6>
                <router-link class="no_results__link" to="/questions/">
                    {{ $t("cp__inbox__no_results__cta") }}
                    <font-awesome-icon
                        class="no_results__link_icon"
                        :icon="['far', 'chevron-right']"
                    />
                </router-link>
            </div>
            <div v-else class="no_results">
                <!-- No results, but some filters are active -->
                <h6 class="no_results__title headline6">
                    {{ $t("cp__inbox__no_filter_results") }}
                </h6>
                <button class="no_results__link" @click="clearFilters">
                    <font-awesome-icon
                        class="no_results__link_icon no_results__link_icon--start"
                        :icon="['far', 'trash']"
                    />
                    {{ $t("cp__inbox__clear_filters") }}
                </button>
            </div>
        </div>
        <div v-show="initialLoadDone" class="inbox_items__list" ref="inboxItemList">
            <InboxChunk
                v-for="inboxChunk in inboxChunksSorted"
                :key="inboxChunk"
                :page="inboxChunk"
                :scrollTop="scrollTop"
                :containerOffsetTop="containerOffsetTop"
                @topDistanceChange="onChunkTopDistanceChange"
                @onLoad="onChunkLoaded"
                ref="inboxChunks"
            />
        </div>
    </div>
</template>

<script>
/*
 * This component only cares about the chunks it is displaying
 * Those chunks in turn request their own data from the store.
 */

import Vue from "vue";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";
import InboxItemsHeader from "@/components/Inbox/Molecules/InboxItemsHeader.vue";
import InboxFilters from "@/components/Inbox/Molecules/InboxFilters.vue";
import InboxChunk from "@/components/Inbox/Organisms/InboxChunk.vue";
import SelectedCheckbox from "@/components/Inbox/Atoms/SelectedCheckbox.vue";
import TextButton from "@/components/Global/Atoms/TextButton.vue";
import setReviewsReadStatus from "@/graphql/inbox/setReviewsReadStatus.gql";
import setReviewsReadStatusAll from "@/graphql/inbox/setReviewsReadStatusAll.gql";

library.add(faChevronRight, faTrash);

export default {
    name: "InboxItems",
    components: {
        FontAwesomeIcon,
        InboxItemsHeader,
        InboxFilters,
        InboxChunk,
        LoaderCircular,
        SelectedCheckbox,
        TextButton,
    },
    data() {
        return {
            scrollTop: 0,
            scrollLocations: {},
            containerOffsetTop: 0,
            scrollDirection: null,
            lockScrollCheck: false,
            internalPage: null,
            windowWidth: null,
        };
    },
    computed: {
        ...mapState({
            inboxChunks: (state) => state.InboxStore.inboxChunks,
            loading: (state) => state.InboxStore.loading,
            paging: (state) => state.InboxStore.paging,
            sorting: (state) => state.InboxStore.sorting,
            inboxChunksDisplayed: (state) => state.InboxStore.inboxChunksDisplayed,
            initialLoadDone: (state) => state.InboxStore.initialLoadDone,
            selectState: (state) => state.InboxStore.selectState,
            areAllSelected: (state) => state.InboxStore.areAllSelected,
            numerOfSelectedItems: (state) => state.InboxStore.numerOfSelectedItems,
            numerOfSelectedUnreadItems: (state) => state.InboxStore.numerOfSelectedUnreadItems,
            locale: (state) => state.LanguageStore.currentLanguage,
        }),
        ...mapGetters({
            filtersActive: "InboxStore/getActiveFilters",
            getIdsOfSelectedItems: "InboxStore/getIdsOfSelectedItems",
        }),
        inboxChunksSorted() {
            return this.inboxChunksDisplayed.sort((a, b) => a - b).filter((chunk) => chunk > -1);
        },
        inboxChunksInitialLoading() {
            let loading = false;
            Object.values(this.inboxChunks).forEach((inboxChunk) => {
                if (!inboxChunk) return;
                if (inboxChunk.loading === true) loading = true;
            });
            return loading;
        },
        currentPage() {
            return parseInt(this.$route.params.page, 10);
        },
        isOverlay() {
            return this.windowWidth < 1044;
        },
        displayMarkAsUnread() {
            return (
                this.numerOfSelectedUnreadItems === 0 &&
                this.numerOfSelectedItems > 0 &&
                !this.areAllSelected
            );
        },
    },
    watch: {
        inboxChunksInitialLoading() {
            // Go to deep linked chunk
            if (this.inboxChunksInitialLoading) return;
            if (this.initialLoadDone) return;
            this.$store.commit("InboxStore/setInitialLoadDone", {
                initialLoadDone: true,
            });
            const chunkNode = this.$refs.inboxChunks.find(
                (chunk) => chunk.page === this.currentPage - 1
            );
            if (!chunkNode) return;
            const chunkElement = chunkNode.$el;
            setTimeout(() => {
                const chunkBounding = chunkElement.getBoundingClientRect();
                this.$refs.inboxItemList.scrollTop = chunkBounding.height;
            }, 16);
        },
        currentPage() {
            this.getPages();
        },
        sorting: {
            deep: true,
            handler() {
                this.$refs.inboxItemList.scrollTop = 0;
                this.$store.commit("InboxStore/clearData");
                this.internalPage = 1;
                this.scrollLocations = {};
                window.requestAnimationFrame(() => {
                    this.getInitialChunks();
                });
            },
        },
        internalPage() {
            if (this.internalPage == this.$route.params.page) return;
            this.$router.push({
                name: this.$route.name,
                params: {
                    page: this.internalPage,
                },
                query: this.$route.query,
            });
        },
    },
    methods: {
        ...mapMutations({
            setSelectState: "InboxStore/setSelectState",
            selectOrUnselectAllReviews: "InboxStore/selectOrUnselectAllReviews",
        }),
        ...mapActions({
            clearFilters: "InboxStore/clearFilters",
            reloadAllInboxChunks: "InboxStore/reloadAllInboxChunks",
        }),
        getPages() {
            // Ensure the previous and next page are loaded
            if (this.inboxChunksDisplayed.indexOf(this.currentPage + 1) < 0) {
                this.$store.commit("InboxStore/addDisplayChunk", {
                    chunkPage: this.currentPage + 1,
                });
            }
            if (
                this.inboxChunksDisplayed.indexOf(this.currentPage - 1) < 0 &&
                this.currentPage - 1 > 0
            ) {
                this.$store.commit("InboxStore/addDisplayChunk", {
                    chunkPage: this.currentPage - 1,
                });
            }
        },
        onChunkTopDistanceChange({ rect, page, loaded }) {
            Vue.set(this.scrollLocations, page, {
                rect,
                page,
                loaded,
            });
            this.pollScroll();
        },
        pollScroll() {
            if (!this.scrollDirection) return;
            if (this.lockScrollCheck) return;

            const chunkNode = this.$refs.inboxChunks.find(
                (chunk) => chunk.page === this.currentPage
            );
            const chunkElement = chunkNode.$el;
            const chunkBounding = chunkElement.getBoundingClientRect();
            const nextChunkNode = this.$refs.inboxChunks.find(
                (chunk) => chunk.page === this.currentPage + 1
            );
            const nextChunkElement = nextChunkNode.$el;
            const nextChunkBounding = nextChunkElement.getBoundingClientRect();
            const prevChunkNode = this.$refs.inboxChunks.find(
                (chunk) => chunk.page === this.currentPage - 1
            );

            if (this.scrollDirection === "up" && nextChunkBounding.top < 0) {
                this.internalPage = nextChunkNode.page;
            }
            if (
                prevChunkNode &&
                !prevChunkNode.loading &&
                this.scrollDirection === "down" &&
                chunkBounding.top > 0
            ) {
                this.internalPage = prevChunkNode.page;
            }
        },
        onChunkLoaded({ page }) {
            const container = this.$refs.inboxItemList;
            const { scrollTop } = container;
            if (scrollTop > 100) return;
            const chunkNode = this.$refs.inboxChunks.find((chunk) => chunk.page === page);
            const chunkElement = chunkNode.$el;
            const chunkBounding = chunkElement.getBoundingClientRect();
            // The first page is an exception in that it doesn't have to scroll down
            if (this.currentPage === 1) return;
            container.scrollTop = chunkBounding.height + scrollTop;
        },
        getInitialChunks() {
            this.$store.commit("InboxStore/addDisplayChunk", {
                chunkPage: this.currentPage,
            });
            this.$store.commit("InboxStore/addDisplayChunk", {
                chunkPage: this.currentPage + 1,
            });
            if (this.currentPage > 1) {
                this.$store.commit("InboxStore/addDisplayChunk", {
                    chunkPage: this.currentPage - 1,
                });
            }
        },
        async setReviewsReadStatus(status) {
            try {
                if (this.areAllSelected) {
                    const filters = { ...this.sorting };
                    delete filters.sortBy;
                    delete filters.sortOrder;
                    await this.$apollo.mutate({
                        mutation: setReviewsReadStatusAll,
                        variables: {
                            input: { readStatus: status, language: this.locale, ...filters },
                        },
                        context: {
                            headers: {
                                "X-Shop-Uuid": this.$store.state.AccountStore.shopData.externalUuid,
                            },
                        },
                    });
                } else {
                    await this.$apollo.mutate({
                        mutation: setReviewsReadStatus,
                        variables: {
                            input: {
                                readStatus: status,
                                language: this.locale,
                                merchantReviews: this.getIdsOfSelectedItems("merchant"),
                                productReviews: this.getIdsOfSelectedItems("product"),
                                productQuestions: this.getIdsOfSelectedItems("question"),
                            },
                        },
                        context: {
                            headers: {
                                "X-Shop-Uuid": this.$store.state.AccountStore.shopData.externalUuid,
                            },
                        },
                    });
                }
                await this.$store.dispatch("pushNotification", {
                    type: "success",
                    title: this.$t("cp__generic__success"),
                    message: this.$t("cp__inbox__mark_review_success_message"),
                });
                this.reloadAllInboxChunks();
                await this.$store.dispatch("NotificationStore/getNotificationCount");
            } catch (error) {
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__generic__error_title"),
                    message: this.$t("cp__generic__error"),
                });
            }
        },
    },
    beforeMount() {
        this.getInitialChunks();
    },
    mounted() {
        const container = this.$refs.inboxItemList;
        this.scrollTop = container.scrollTop;
        this.containerOffsetTop = container.getBoundingClientRect().top;
        this.windowWidth = window.innerWidth;
        container.addEventListener("scroll", () => {
            if (this.scrollTop > container.scrollTop) {
                this.scrollDirection = "down";
            } else {
                this.scrollDirection = "up";
            }
            this.scrollTop = container.scrollTop;
        });
        window.addEventListener("resize", () => {
            this.windowWidth = window.innerWidth;
        });
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox_items {
    background: $grey_bg;
    border-right: 1px solid $grey_alabaster;
    min-width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.inbox_items__list {
    list-style: none;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    // Because this can be a rather large scroll-list, this is added for performance
    transform: translate3d(0, 0, 0);
}
.inbox_items__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
}
.no_results_holder {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
}
.no_results__title {
    width: 100%;
    text-align: center;
    display: inline-block;
}
.no_results__link {
    @extend %body1_style;
    margin-top: 16px;
    text-decoration: none;
    color: $blue;
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 0px;
    background: transparent;
    border: 0;
    outline: 0;

    &:hover {
        cursor: pointer;
    }
    div {
        display: inline;
    }
}
.no_results__link_icon {
    margin-left: 8px;
}
.no_results__link_icon--start {
    margin-left: 0px;
    margin-right: 8px;
}
.selected_checkbox_containter {
    display: flex;
    background: $white;
    padding: 0 0 10px 10px;

    button:last-of-type {
        margin-left: auto;
        margin-right: 10px;
    }

    button:first-of-type {
        margin-left: inherit;
    }
}
.selected_checkbox {
    margin-right: 10px;
}
</style>
