<template>
    <!-- Container is temporary, because a banner has to be displayed above the inbox -->
    <div class="inbox_container">
        <div class="inbox" ref="inbox">
            <div
                v-if="resizing"
                class="inbox__resize_box"
                @mousemove="onResizeDrag"
                @mouseup="onResizeEnd"
            />
            <InboxItems :style="inboxItemsStyle" class="inbox__items" />
            <div
                @mousedown="onResizeStart"
                class="inbox__resizer"
                :class="{ 'inbox__resizer--resizing': resizing }"
            />
            <InboxDetail ref="inboxDetail" class="inbox__detail" :width="inboxDetailWidth" />
            <ConfirmPopup
                v-if="reviewResidesInDifferentDivision && presentAccountSwitchDialogue"
                :popupID="`${$store.state.InboxStore.reviewContext.divisionId}`"
                :title="$t('cp__inbox__error__review_access__switch_title')"
                :message="divisionSwitchMessage"
                confirm-btn="Switch"
                :cancel-btn="$t('cp__generic__cancel')"
                :closeAllowed="false"
                @confirm="switchDivisionAndRouteToReview"
            />
        </div>
    </div>
</template>

<script>
import { vueWindowSizeMixin } from "vue-window-size";
import ConfirmPopup from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/ConfirmPopup.vue";
import { mapMutations } from "vuex";
import InboxItems from "@/components/Inbox/Organisms/InboxItems.vue";
import InboxDetail from "@/components/Inbox/Organisms/InboxDetail.vue";

export default {
    name: "Inbox",
    mixins: [vueWindowSizeMixin],
    components: {
        ConfirmPopup,
        InboxItems,
        InboxDetail,
    },
    data() {
        return {
            inboxItemsWidth: localStorage.getItem("inboxItemsWidth") || 33, // VW
            resizing: false,
            inboxDetailWidth: 0,
            reviewResidesInDifferentDivision: false,
            presentAccountSwitchDialogue: false,
        };
    },
    computed: {
        inboxItemsStyle() {
            return {
                width: `${this.inboxItemsWidth}vw`,
            };
        },
        windowSizeSum() {
            return this.windowWidth + this.windowHeight;
        },
        divisionSwitchMessage() {
            const { divisions } = this.$store.state.AccountStore;
            const divisionToSwitchTo = divisions.filter(
                (division) =>
                    division.externalId === this.$store.state.InboxStore.reviewContext.divisionId
            )[0];
            return this.$t("cp__inbox__error__review_access__switch_message", {
                account: divisionToSwitchTo?.name,
            });
        },
    },
    watch: {
        windowSizeSum() {
            this.setInboxDetailWidth();
        },
    },
    methods: {
        ...mapMutations({
            setSorting: "InboxStore/setSorting",
        }),
        onResizeStart() {
            this.resizing = true;
        },
        onResizeDrag(event) {
            window.requestAnimationFrame(() => {
                const width = Math.round(((event.clientX - 248) / window.innerWidth) * 100);
                this.inboxItemsWidth = width;
            });
            this.setInboxDetailWidth();
        },
        onResizeEnd() {
            this.resizing = false;
            localStorage.setItem("inboxItemsWidth", this.inboxItemsWidth);
        },
        setInboxDetailWidth() {
            if (!this.$refs.inboxDetail.$el) return 1000;
            this.inboxDetailWidth = this.$refs.inboxDetail.$el.getBoundingClientRect().width;
            document.documentElement.style.setProperty(
                "--inbox_detail_width",
                `${this.inboxDetailWidth}px`
            );
        },
        async switchDivisionAndRouteToReview() {
            const { reviewContext } = this.$store.state.InboxStore;
            await this.$store.dispatch("AccountStore/switchAccount", {
                uuid: reviewContext.divisionUuid,
                executeOnAccountSwitch: true,
                apolloClient: this.$apollo,
            });
            this.presentAccountSwitchDialogue = false;
            await this.$router.push(`/${reviewContext.inboxPath}`).catch(() => {});
        },
        setSortingAndFilters() {
            if (!this.$route.query) return;

            const filterableQueryParam = [
                "type",
                "fromRating",
                "toRating",
                "sortBy",
                "sortOrder",
                "isPublished",
                "isAnswered",
                "isReplied",
                "isRevised",
                "fromDate",
                "toDate",
                "onlyUnread",
            ];
            const updatedSorting = { ...this.$store.state.InboxStore.sorting };
            Object.keys(this.$route.query).forEach((queryParam) => {
                if (!filterableQueryParam.includes(queryParam)) return;
                updatedSorting[queryParam] = this.$route.query[queryParam];
            });

            this.setSorting({ sorting: updatedSorting });
        },
    },
    async mounted() {
        this.setInboxDetailWidth();
        await this.$store.dispatch("NotificationStore/getNotificationCount");

        this.setSortingAndFilters();

        // Fetching Id for links to inbox from legacy routes
        const reviewId = this.$route.query.reviewId || this.$route.params.ID;
        const reviewType =
            this.$route.params.type === "merchant" ? "company" : this.$route.params.type;
        if (!reviewId) return;

        // Get context related to review
        const { divisions } = this.$store.state.AccountStore;
        await this.$store.dispatch("InboxStore/getDivisionByReviewId", {
            apolloClient: this.$apollo,
            reviewId,
            reviewType,
            divisions,
        });
        const { reviewContext } = this.$store.state.InboxStore;

        // sometimes the API returns divisionId of null
        if (!reviewContext.divisionId) return;

        if (this.$store.state.AccountStore.shopData.externalId !== reviewContext.divisionId) {
            // Prompt account switch when review is in another division
            // @todo: dont show prompt when review is within a division outside of this account.
            this.reviewResidesInDifferentDivision = true;
            this.presentAccountSwitchDialogue = true;
        } else {
            // Review is in same account. Gracefully bring user to review in new route.
            await this.$router.push(`/${reviewContext.inboxPath}`).catch(() => {});
        }

        await this.$store.dispatch("NotificationStore/getNotificationCount");
    },
    beforeDestroy() {
        this.$store.commit("InboxStore/clearData");
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
    font-size: 16px;
    height: calc(100vh - 56px);

    @media (max-width: 1000px) {
        @include viewportHeight(100, 48px);
    }
}
.inbox__items {
    width: 33vw;
    max-width: 542px;
    min-width: 300px;
    height: 100%;
    flex-shrink: 0;
    overflow: hidden;

    @media (max-width: 1043px) {
        // The width is dragable, and thus set inline by Vue,
        // this !important is warranted because on smaller resolutions we need an overwrite
        max-width: 100% !important;
        width: 100% !important;
    }
}
.inbox__resize_box {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 11;
    cursor: ew-resize;
}
.inbox__resizer {
    width: 10px;
    height: 100%;
    cursor: ew-resize;
    position: relative;
    z-index: 2;
    &:hover,
    &.inbox__resizer--resizing {
        border-left: 1px solid $grey_shark;
    }
    @media (max-width: 1043px) {
        display: none;
    }
}
.inbox__detail {
    width: 100%;
    flex-shrink: 6;
    overflow: hidden;
    height: calc(100vh - 56px);

    @media (max-width: 1000px) {
        @include viewportHeight(100, 48px);
    }
}
</style>
