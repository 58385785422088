<template>
    <div class="legacy-cp">
        <iframe
            title="content"
            @load="load"
            class="legacy-cp-iframe"
            ref="iframe"
            :style="iframeStyle"
        >
        </iframe>
        <div v-if="isDisabledMobile" class="legacy-cp__modal">
            <div class="legacy-cp__modal_content">
                <!-- eslint-disable -->
                <svg width="185px" height="129px" viewBox="0 0 185 129" version="1.1">
                    <g id="Graphic" transform="translate(0.000000, 0.000000)">
                        <rect
                            id="Rectangle"
                            stroke="#192454"
                            stroke-width="2.4"
                            fill="#FFFFFF"
                            x="1.2"
                            y="15.6"
                            width="181.8"
                            height="111.6"
                        ></rect>
                        <rect
                            id="Rectangle"
                            stroke="#192454"
                            stroke-width="2.4"
                            fill="#E5E5EA"
                            x="1.2"
                            y="1.2"
                            width="181.8"
                            height="14.4"
                        ></rect>
                        <path
                            d="M11.4,6.6 C12.3941125,6.6 13.2,7.40588745 13.2,8.4 C13.2,9.39411255 12.3941125,10.2 11.4,10.2 C10.4058875,10.2 9.6,9.39411255 9.6,8.4 C9.6,7.40588745 10.4058875,6.6 11.4,6.6 Z M16.8,6.6 C17.7941125,6.6 18.6,7.40588745 18.6,8.4 C18.6,9.39411255 17.7941125,10.2 16.8,10.2 C15.8058875,10.2 15,9.39411255 15,8.4 C15,7.40588745 15.8058875,6.6 16.8,6.6 Z M22.2,6.6 C23.1941125,6.6 24,7.40588745 24,8.4 C24,9.39411255 23.1941125,10.2 22.2,10.2 C21.2058875,10.2 20.4,9.39411255 20.4,8.4 C20.4,7.40588745 21.2058875,6.6 22.2,6.6 Z"
                            id="Combined-Shape"
                            fill="#192454"
                        ></path>
                        <g
                            id="expand-alt"
                            transform="translate(75.600000, 54.600000)"
                            fill="#192454"
                            fill-rule="nonzero"
                        >
                            <path
                                d="M14.912175,18.263625 L15.336375,18.687825 C15.687825,19.039275 15.687825,19.609125 15.336375,19.96065 L4.097025,31.2 L8.7,31.2 C9.197025,31.2 9.6,31.602975 9.6,32.1 L9.6,32.7 C9.6,33.197025 9.197025,33.6 8.7,33.6 L0.9,33.6 C0.402975,33.6 0,33.197025 0,32.7 L0,24.9 C0,24.402975 0.402975,24 0.9,24 L1.5,24 C1.997025,24 2.4,24.402975 2.4,24.9 L2.4,29.502975 L13.63935,18.263625 C13.990875,17.9121 14.560725,17.9121 14.912175,18.263625 Z M32.7,0 L24.9,0 C24.402975,0 24,0.402975 24,0.9 L24,1.5 C24,1.997025 24.402975,2.4 24.9,2.4 L29.502975,2.4 L18.263625,13.63935 C17.912175,13.9908 17.912175,14.56065 18.263625,14.912175 L18.687825,15.336375 C19.039275,15.687825 19.609125,15.687825 19.96065,15.336375 L31.2,4.097025 L31.2,8.7 C31.2,9.197025 31.602975,9.6 32.1,9.6 L32.7,9.6 C33.197025,9.6 33.6,9.197025 33.6,8.7 L33.6,0.9 C33.6,0.402975 33.197025,0 32.7,0 Z"
                                id="Shape"
                            ></path>
                        </g>
                    </g>
                </svg>
                <!-- eslint-enable -->
                <h5 class="headline5">
                    {{ $t("cp__general__screen_size_unsupported__title") }}
                </h5>
                <router-link to="/inbox" class="legacy-cp__modal_button">
                    <Button>
                        {{ $t("cp__general__screen_size_unsupported__button_label") }}
                    </Button>
                </router-link>
            </div>
        </div>
        <div v-if="loading" class="loader-container">
            <div class="loader-content">
                <LoaderCircular :height="72" class="loader-content__loader" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import queryString from "query-string";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";
import { vueWindowSizeMixin } from "vue-window-size";
import navigationStructure from "@/data/main-navigation-structure.yaml";

export default {
    name: "LegacyCP",
    mixins: [vueWindowSizeMixin],
    components: {
        LoaderCircular,
        Button,
    },
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        ...mapState({
            forcedSwitchPopupOpen: (state) => state.AccountStore.forcedSwitchPopupOpen,
        }),
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
            language: "LanguageStore/getLanguage",
        }),
        route() {
            const route = `${process.env.VUE_APP_API_BASE_URL}${this.$route.fullPath}`;
            const paramDataFromRoute = queryString.parse(route.split("?")[1]);
            // Merge function param data
            const mergedParams = queryString.stringify({
                ...paramDataFromRoute,
                hideMenu: true,
            });
            return `${route.split("?")[0]}?${mergedParams}&language=${this.language}`;
        },
        iframeStyle() {
            let height = this.$store.state.iframeHeight;
            let opacity = 1;
            if (this.loading === true) {
                height = 1;
                opacity = 0;
            }
            const menuHeight = this.windowHeight < 1000 ? 48 : 56;
            const iframeMinHeight = this.windowHeight - menuHeight;
            if (height < iframeMinHeight) height = iframeMinHeight;
            return {
                height: `${height}px`,
                opacity,
            };
        },
        isDisabledMobile() {
            if (this.windowWidth > 768) return false;
            const matchedURL = Object.keys(navigationStructure)
                .map((key) => ({ key, ...navigationStructure[key] }))
                .find((navigationItem) => this.route.indexOf(navigationItem.link) > -1);
            if (!matchedURL) return false;
            return !matchedURL.mobile;
        },
    },
    watch: {
        // Doing the replace of the URL, because the iFrame pushes to the history otherwise.
        route(route) {
            this.loading = true;
            this.$refs.iframe.contentWindow.location.replace(route);
        },
        // Reload on account switch
        activeAccount() {
            if (this.forcedSwitchPopupOpen) return;
            this.loading = true;
            this.$refs.iframe.contentWindow.location.replace(this.route);
        },
        language() {
            this.loading = true;
            this.$refs.iframe.contentWindow.location.replace(this.route);
        },
    },
    methods: {
        load() {
            this.loading = false;
        },
    },
    mounted() {
        this.loading = true;
        this.$refs.iframe.contentWindow.location.replace(this.route);
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.legacy-cp {
    width: 100%;
    height: calc(100vh - 48px);
    background-color: $white;
}
.legacy-cp-iframe {
    width: 100%;
    height: calc(100vh - 48px);
    border: 0px;
}
$loader_size: 88px;
.loader-container {
    width: $loader_size;
    height: $loader_size;
    z-index: 4;
    left: 50vw;
    position: fixed;
    bottom: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    opacity: 1;
}
.loader-content__loader {
    width: $loader_size - 20px;
}
.legacy-cp__modal {
    z-index: 5;
    width: 100vw;
    height: calc(100vh - 48px);
    position: fixed;
    top: 48px;
    left: 0px;
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.legacy-cp__modal_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    text-align: center;
}
.legacy-cp__modal_button {
    margin-top: 24px;
    text-decoration: none;
}
</style>
