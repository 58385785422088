<template>
    <Button
        class="inbox_hamburger_button"
        :class="{
            'inbox_hamburger_button--toggled': toggled,
        }"
        :buttonHeight="56"
    >
        <span class="inbox_hamburger_button__dots">
            <div class="button_dot button_dot--dot_1"></div>
            <div class="button_dot button_dot--dot_2"></div>
            <div class="button_dot button_dot--dot_3"></div>
        </span>
    </Button>
</template>

<script>
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";

export default {
    name: "InboxHamburgerButtton",
    components: {
        Button,
    },
    props: {
        toggled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox_hamburger_button {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
}
.inbox_hamburger_button__dots {
    transform: rotateZ(90deg);
    display: flex;
    justify-content: inherit;
    align-items: inherit;
    transition: all 300ms;
}
.button_dot {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: $white;
    transition: all 300ms;
}
.button_dot--dot_1,
.button_dot--dot_3 {
    transform-origin: 50% 100%;
}
.button_dot--dot_2 {
    margin: 0px 1px;
}
.inbox_hamburger_button--toggled {
    .inbox_hamburger_button__dots {
        transform: rotateZ(0deg);
    }
    .button_dot--dot_1,
    .button_dot--dot_3 {
        width: 12px;
    }
    .button_dot--dot_1 {
        transform: rotateZ(45deg) translate(2px, -2px);
    }
    .button_dot--dot_3 {
        transform: rotateZ(-45deg) translate(-2px, -2px);
    }
    .button_dot--dot_2 {
        margin: 0px;
        transform: translateY(4px);
    }
}
</style>
