import { render, staticRenderFns } from "./InboxDetail.vue?vue&type=template&id=75097521&scoped=true&"
import script from "./InboxDetail.vue?vue&type=script&lang=js&"
export * from "./InboxDetail.vue?vue&type=script&lang=js&"
import style0 from "./InboxDetail.vue?vue&type=style&index=0&id=75097521&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75097521",
  null
  
)

export default component.exports