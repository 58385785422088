<template>
    <form
        class="inbox_response_input"
        :class="{
            'inbox_response_input--small': isSmall,
            'inbox_response_input--expanded': textAreaExpanded,
            'inbox_response_input--single_column': isSingleColumn,
            'inbox_response_input--has_response': hasResponse,
            'inbox_response_input--is_focussed': isFocussed,
        }"
    >
        <EventListener v-if="textAreaExpanded" @keydown.enter.meta="requestSubmit" />
        <EventListener v-if="textAreaExpanded" @keydown.enter.ctrl="requestSubmit" />
        <EventListener v-if="textAreaExpanded" @keydown.escape="collapse" />
        <div
            v-if="hasUnsavedResponse && textAreaExpanded"
            class="inbox_response_input__unsaved_changes"
        >
            <font-awesome-icon :icon="['far', 'info-circle']" />
            <span class="inbox_response_input__unsaved_changes_text">
                {{ $t("cp__inbox__reply__unsaved_changes") }}
            </span>
        </div>
        <div class="inbox_response_input__field">
            <TextInput
                ref="input"
                name="reply"
                v-model="inputValue"
                :label="$t('cp__inbox__reply_input__placeholder')"
                :multiline="true"
                :valueMaxLength="maxLength"
                :displayValueMaxLength="textAreaExpanded"
                class="inbox_response_input__input"
                :class="{ inbox_response_input__input_expanded: textAreaExpanded }"
                @focus="onFocus"
            />
        </div>
        <div class="inbox_response_input__controls" ref="controls">
            <div>
                <div
                    v-if="showValidationMessages"
                    class="inbox_response_input__validation_messages"
                >
                    <!-- Validation, too many characters -->
                    <ValidationMessage
                        :validationMessage="
                            $t('cp__validation__error__too_many_characters', { max: maxLength })
                        "
                        :displayInvalid="maxLengthWarningLevel === 'error'"
                    />
                    <!-- Validation, required -->
                    <ValidationMessage
                        :validationMessage="$t('cp__validation__error__required')"
                        :displayInvalid="!this.inputValue || this.inputValue.length < 1"
                    />
                </div>
                <div class="inbox_response_input__disclaimer">{{ replyExplanationText }}</div>
                <Checkbox
                    v-if="!isLoading && hasSetPublicCheckbox"
                    v-model="setPublic"
                    :label="$t('cp__inbox__questions__reply_input__public_checkbox_label')"
                />
            </div>
            <div v-if="!isLoading" class="inbox_response_input__buttons">
                <Button
                    class="inbox_response_input__button"
                    type="button"
                    buttonStyle="white"
                    @click.native="collapse"
                >
                    {{ $t("cp__general__close") }}
                </Button>
                <Button
                    class="inbox_response_input__button"
                    type="button"
                    @click.native="requestSubmit"
                >
                    {{ buttonMessage }}
                </Button>
            </div>
            <div v-else class="inbox_response_input__loading">
                <LoaderCircular :height="48" />
            </div>
        </div>
    </form>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ValidationMessage from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/ValidationMessage/ValidationMessage.vue";
import InputBase from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/mixins/InputBase";
import TextInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/TextInput.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import Checkbox from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Checkbox.vue";
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";
import { vueWindowSizeMixin } from "vue-window-size";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPaperPlane, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import EventListener from "@/components/Global/Atoms/EventListener.vue";

library.add(faPaperPlane, faInfoCircle);

export default {
    name: "InboxResponseInput",
    mixins: [InputBase, vueWindowSizeMixin],
    components: {
        Button,
        FontAwesomeIcon,
        LoaderCircular,
        EventListener,
        Checkbox,
        ValidationMessage,
        TextInput,
    },
    props: {
        isSmall: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        ID: {
            required: true,
        },
        onHold: {
            type: Boolean,
            default: false,
        },
        hasResponse: {
            type: Boolean,
            default: false,
        },
        hasUnsavedResponse: {
            type: Boolean,
            default: false,
        },
        isSingleColumn: {
            type: Boolean,
            default: false,
        },
        hasSetPublicCheckbox: {
            type: Boolean,
            default: false,
        },
        replyButtonText: {
            type: String,
        },
        maxLength: {
            type: Number,
            default: 500,
        },
    },
    data() {
        return {
            valid: true,
            isFocussed: false,
            textAreaMaxHeight: 48,
            setPublic: true,
            showValidationMessages: false,
        };
    },
    computed: {
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
        }),
        ...mapState({
            responseInputCollapsed: (state) => state.InboxStore.responseInputCollapsed,
        }),
        accountImageSize() {
            return this.isSmall ? 24 : 56;
        },
        requiredMessage() {
            return this.$t("general_input__validation__required", "This input field is required.");
        },
        buttonMessage() {
            const { onHold, hasResponse } = this;
            let buttonMessage = this.$t("cp__inbox__reply_input__submit_button--publish");
            if (onHold) buttonMessage = this.$t("cp__inbox__reply_input__submit_button--approve");
            if (hasResponse)
                buttonMessage = this.$t("cp__inbox__reply_input__submit_button--update");
            if (this.replyButtonText)
                buttonMessage = this.$t("cp__inbox__answer_input__submit_button--publish");
            return buttonMessage;
        },
        textAreaExpanded() {
            return (
                !this.responseInputCollapsed &&
                (this.isFocussed || (this.inputValue && this.inputValue.length > 0))
            );
        },
        replyExplanationText() {
            const { onHold, hasSetPublicCheckbox } = this;
            let replyExplanation = this.$t("cp__inbox__reply_explanation--approved");
            if (onHold) replyExplanation = this.$t("cp__inbox__reply_explanation--on_hold");
            if (hasSetPublicCheckbox)
                replyExplanation = this.$t("cp__inbox__questions__reply_input__helptext");
            return replyExplanation;
        },
        characterCounterValue() {
            const inputValue = this.inputValue || "";
            return this.maxLength - inputValue.length;
        },
        maxLengthWarningLevel() {
            let maxLengthWarningLevel;
            const inputValue = this.inputValue || "";
            const percentageOfCharactersUsed = 1 - inputValue.length / this.maxLength;
            if (percentageOfCharactersUsed < 0.15) maxLengthWarningLevel = "warning";
            if (this.characterCounterValue < 0) maxLengthWarningLevel = "error";
            return maxLengthWarningLevel;
        },
    },
    watch: {
        inputValue() {
            this.valid = this.inputValue && this.inputValue.length > 0;
        },
        isLoading(newLoading, oldLoading) {
            if (oldLoading && !newLoading) {
                this.collapse();
            }
        },
        textAreaExpanded(textAreaExpanded) {
            const emitEvent = textAreaExpanded ? "requestMoreSpace" : "requestLessSpace";
            this.$emit(emitEvent);
        },
        ID() {
            this.collapse();
        },
    },
    methods: {
        requestSubmit() {
            if (
                !this.inputValue ||
                this.inputValue.length < 1 ||
                this.maxLengthWarningLevel === "error"
            ) {
                this.showValidationMessages = true;
                this.valid = false;
                return false;
            }
            this.$emit("submitRequest", { reply: this.value, setPublic: this.setPublic });
            this.inputValue = null;
        },
        onFocus() {
            this.isFocussed = true;
            this.$store.commit("InboxStore/setResponseInputCollapsed", { isCollapsed: false });
        },
        onBlur() {
            this.isFocussed = false;
        },
        collapse() {
            this.onBlur();
            this.$refs?.input.blur();
            this.$store.commit("InboxStore/setResponseInputCollapsed", { isCollapsed: true });
            this.inputValue = null;
        },
        focus() {
            this.$refs.input.focus();
            this.onFocus();
        },
        pollControlBarHeight() {
            this.setControlBarHeight();
            window.setTimeout(() => {
                this.pollControlBarHeight();
            }, 10);
        },
        setControlBarHeight() {
            const { controls } = this.$refs;
            if (!controls) return;
            document.documentElement.style.setProperty(
                "--control-bar-height",
                `${controls.offsetHeight}px`
            );
        },
    },
    mounted() {
        this.collapse();
        this.pollControlBarHeight();
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox_response_input {
    position: relative;
    width: 100%;
    background-color: $white;
    overflow: hidden;
}
.inbox_response_input--has_response {
    .inbox_response_input__field,
    .inbox_response_input__unsaved_changes {
        opacity: 0;
        pointer-events: none;
    }
}
.inbox_response_input__input::v-deep.text_input_holder {
    max-width: 100%;
}
.inbox_response_input--expanded {
    .inbox_response_input__input {
        height: 200px;
    }
    .inbox_response_input__unsaved_changes {
        opacity: 1;
    }
    .inbox_response_input__field {
        pointer-events: all;
        opacity: 1;
        margin-bottom: var(--control-bar-height);
    }
    .inbox_response_input__controls {
        transform: translateY(0%);
    }
}
.inbox_response_input__unsaved_changes {
    display: flex;
    color: $blue;
    margin-bottom: 8px;
    line-height: 0px;
    svg {
        margin-top: 2px;
    }

    .inbox_response_input__unsaved_changes_text {
        @extend %body2_style;
        margin: 0 0 0 8px;
    }
}
.inbox_response_input__field {
    width: 100%;
    margin-bottom: 0px;
    will-change: margin;
    transition: all 300ms;
    display: flex;
    position: relative;
}
.inbox_response_input__counter {
    position: absolute;
    bottom: 0px;
    right: 16px;
    color: $grey_mischka;
    @extend %body2_style;
    &[data-warning-level="warning"] {
        color: $orange;
    }
    &[data-warning-level="error"] {
        color: $red;
    }
}
.inbox_response_input__disclaimer {
    @extend %body2_style;
    height: auto !important;
    color: $grey_manatee;
    padding: 8px 0 12px;
}
.inbox_response_input__input {
    @extend %body2_style;
    width: 100%;
    border: 0px;
    outline: 0px;
    resize: none;
    will-change: height;
    transition: all 300ms;
    overflow: hidden;
}
.inbox_response_input__input_expanded::v-deep .text_input {
    height: 100% !important;
}
.inbox_response_input__controls {
    position: absolute;
    bottom: 0px;
    width: 100%;
    transform: translateY(calc(100% + 22px));
    transition: all 300ms;
}
.inbox_response_input__buttons,
.inbox_response_input__loading {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 48px;
}
.inbox_response_input__button ~ .inbox_response_input__button {
    margin: 0 0 0 16px;
}
</style>
