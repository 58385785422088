<template>
    <div class="number_range_slider_knob" ref="knob">
        <div class="number_range_slider_knob__innner"></div>
    </div>
</template>

<script>
import InputBase from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/mixins/InputBase";
import { mapMutations } from "vuex";

export default {
    name: "knob",
    mixins: [InputBase],
    data() {
        return {
            dragging: false,
            parentCoordinates: null,
            offsetX: null,
        };
    },
    methods: {
        ...mapMutations(["setTrayDragAllowed"]),
        touchStartAdapter({ targetTouches }) {
            const offsetX = targetTouches[0].pageX - this.$refs.knob.getBoundingClientRect().left;
            this.dragStart({ offsetX });
        },
        touchMoveAdapter({ targetTouches }) {
            this.dragMove({ pageX: targetTouches[0].pageX });
        },
        mouseDownAdapter({ offsetX }) {
            this.dragStart({ offsetX });
        },
        mouseMoveAdapter({ pageX }) {
            this.dragMove({ pageX });
        },
        dragStart({ offsetX }) {
            this.setTrayDragAllowed({ trayDragAllowed: false });
            const parentElement = this.$parent.$refs.NumberRangeSliderElement;
            const parentClientRect = parentElement.getBoundingClientRect();
            this.parentCoordinates = {
                top: parentClientRect.top,
                left: parentClientRect.left,
                width: parentClientRect.width,
            };
            this.offsetX = offsetX;
            this.dragging = true;
        },
        dragMove({ pageX }) {
            if (!this.dragging) return;
            const deltaX = pageX - this.parentCoordinates.left - this.offsetX;
            let percentage = deltaX / this.parentCoordinates.width;
            if (percentage < 0) percentage = 0;
            if (percentage > 1) percentage = 1;
            window.requestAnimationFrame(() => {
                this.inputValue = percentage;
            });
        },
        dragEnd() {
            if (!this.dragging) return;
            this.dragging = false;
            this.setTrayDragAllowed({ trayDragAllowed: true });
            this.$emit("dragEnd");
        },
    },
    mounted() {
        const { knob } = this.$refs;
        // Mouse Handlers:
        const passive = true;
        knob.addEventListener("mousedown", (event) => this.mouseDownAdapter(event), false);
        document.addEventListener("mousemove", (event) => this.mouseMoveAdapter(event), false);
        document.addEventListener("mouseup", (event) => this.dragEnd(event), false);
        // Touch Handlers:
        knob.addEventListener("touchstart", (event) => this.touchStartAdapter(event), { passive });
        knob.addEventListener("touchmove", (event) => this.touchMoveAdapter(event), { passive });
        knob.addEventListener("touchend", (event) => this.dragEnd(event), { passive });
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.number_range_slider_knob {
    @extend %body2_style;
    width: 40px;
    height: 54px;
    position: absolute;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.number_range_slider_knob__innner {
    width: 28px;
    height: 28px;
    border: 3px solid $blue;
    background-color: $white;
    border-radius: 50%;
}
</style>
