<template>
    <table v-if="!loading" class="table">
        <tbody v-for="row in rows" :key="`info_table_${ID}${Math.random()}${row.value}`">
            <tr v-if="!row.editable">
                <td
                    class="detail__table_row_name"
                    :class="{ 'detail__table_row_name--compact': compact }"
                >
                    {{ row.name }}
                </td>
                <td class="detail__table_row_value">{{ row.value }}</td>
            </tr>
            <EditableRow
                v-else
                v-model="row.value"
                :reviewID="ID"
                :rowID="row.rowID"
                :name="row.name"
                :compact="compact"
            />
        </tbody>
    </table>
</template>

<script>
import { mapGetters } from "vuex";
import EditableRow from "@/components/Inbox/Atoms/EditableRow.vue";

export default {
    name: "InboxTable",
    components: {
        EditableRow,
    },
    props: {
        ID: {
            required: true,
        },
        compact: {
            type: Boolean,
            default: false,
        },
        rows: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
        }),
        loading() {
            return !this.activeAccount || this.rows.length === 0;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
$table_width: 332px;

.table {
    width: 100%;
    max-width: $table_width;
    border: 1px solid $grey_athens;
    border-top: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-spacing: 0 4px;
    padding: 4px 0;
    td {
        padding: 4px 0 4px 16px;
    }
}
.detail__table_row_name {
    color: $grey_mid;
    font-size: 12px;
    word-wrap: break-word;
    hyphens: auto;
}
.detail__table_row_name--compact {
    width: 80px;
}
.detail__table_row_value {
    font-size: 12px;
    font-weight: 600;
    hyphens: auto;
    word-break: break-word;
}
</style>
